<template>
	<div :class="themeClass">
		<div class="maink flex-row margin-b-30">
			<div>
				<div class="task-left-box bg-FF9900">
					<div class="task-left-title flex-column-center-center">
						<img class="task-left-title-bg" :src="taskBg">
						<div class="task-left-title-text font-weight700">任务</div>
						<div class="task-left-title-text font-weight700">集市</div>
					</div>
					<div class="flex-row-space-between font-color-fff padding-lr-20 padding-tb-15">
						<div class="flex-colum-space-between">
							<div class="font-size28 font-weight700 margin-b-10">{{sumCustomerPoint}}</div>
							<div>我的积分</div>
						</div>
						<div class="flex-colum-space-between">
							<div class="font-size28 font-weight700">{{completed}}</div>
							<div>今年已完成任务</div>
						</div>
						<div class="flex-colum-space-between">
							<div class="font-size28 font-weight700">{{toBeCompleted}}</div>
							<div>待完成任务</div>
						</div>
					</div>
					<!-- <div class="flex-row-center-center">
						<div class="iconfont icon-tishi2 self-color-ffff00 font-size16"></div>
						<div class="font-size15 self-color-ffff00 margin-l-10">每天首次分享获得2积分</div>
					</div> -->
				</div>
				<!--区域展示需求成为树结构-->
					<div class="tree-overflow" style="height:calc(100vh - 355px);width:100%;overflow-y: auto" v-if="tabActive == 1">
						<el-tree ref="tree" v-loading="loadingTree" class="width500" :data="treeData" :props="defaultProps"
						 highlight-current :highlight-current-row="true" :filter-node-method="filterNode" node-key="fSaleAreaID"
						 :default-expanded-keys="expandedKeys" :expand-on-click-node="expandClick" @node-click="handleNodeClick"
						 @node-expand="nodeExpand" @node-collapse="nodeCollapse"  :highlight-current="true"  :current-node-key="currentNodeKey">
							<span slot-scope="{ node, data }" style="font-size: 14px;">
								{{ data.fSaleArea }}
							</span>
						</el-tree>
					</div>
				<div v-if="false">
					<!-- 	<el-menu text-color="#333333" active-text-color="#00aaff" >
						<el-menu-item :index="item.fSaleAreaIDs" v-for="(item,index) in noChildren"
							:key="item.fSaleAreaIDs" @click="clickMenu(item)">
							<span slot="title">{{ item.fSaleAreas }}</span>
						</el-menu-item>
					</el-menu> -->
					<div class="el-menu-vertical-demo" :class="{active:item.fSaleAreaIDs === activeClass }"
						v-for="(item,index) in noChildren" :key="item.fSaleAreaIDs" @click="clickMenu(item,index)">
						{{ item.fSaleAreas }}
					</div>
				</div>
				<!-- 	<div v-if="this.leiB=='已完成'">
					<div class="choose-date ">
						<el-date-picker v-model="searchDate" @change="queryListData" unlink-panels type="daterange"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
					</div>
				</div> -->
			</div>
			<div class="task-right-box padding-20 bg-F8F9FE" v-loading="loading">
				<div class="task-card-box">
					<!-- 		<div>
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item></el-breadcrumb-item>
							<el-breadcrumb-item>
								{{(this.label=='全部任务'||this.fSaleAreaIDs=='')?'全部':current.fSaleAreas}}
							</el-breadcrumb-item>
						</el-breadcrumb>
					</div> -->
					<div class="task-card radius10 bg-fff flex-column-align-center">
						<!-- 		<div class="task-card-bg font-size40 font-weight700">
							MARKET
						</div> -->
						<div class="task-card-tabs flex-row-align-flex-end margin-b-20">
							<div :class="{'font-size20 font-weight700':tabActive == 1}" @click="tabActiveChange(1)"
								class="task-card-tab-margin-r font-size16 font-color-333 pointer">
								任务集市
							</div>
							<div @click="tabActiveChange(2)" :class="{'font-size20 font-weight700':tabActive == 2}"
								class="font-size16 font-color-333 pointer">
								我的任务
							</div>
						</div>
						<div style="display: flex;">
							<div v-if="tabActive == 1" style="margin-left: -300px;">
								<el-tabs v-model="activeName" @tab-click="handleClick">
									<el-tab-pane label="全部任务" name="total"></el-tab-pane>
									<el-tab-pane label="订单任务" name="fOrderTask"></el-tab-pane>
									<el-tab-pane label="提货任务" name="fDeliverTask"></el-tab-pane>
									<el-tab-pane label="充值任务" name="fGatherTask"></el-tab-pane>
								</el-tabs>
							</div>
							<div v-if="tabActive == 2" style="margin-left: 5px;">
								<el-tabs v-model="activeName" @tab-click="handleClick">
									<el-tab-pane label="全部任务" name="total"></el-tab-pane>
									<el-tab-pane label="订单任务" name="fOrderTask"></el-tab-pane>
									<el-tab-pane label="提货任务" name="fDeliverTask"></el-tab-pane>
									<el-tab-pane label="充值任务" name="fGatherTask"></el-tab-pane>
								</el-tabs>
							</div>
							<div v-if="tabActive == 2" style="margin-top: -25px;width: 339px;margin-left: -35px;">
								<div class="choose-date ">
									<el-date-picker v-model="searchDate" @change="queryListData" unlink-panels
										type="daterange" range-separator="至" start-placeholder="开始日期"
										end-placeholder="结束日期">
									</el-date-picker>
								</div>
							</div>
						</div>
						<div v-if="tabActive == 1">
							<!-- 全部任务 -->
							<div v-if="activeName == 'total'">
								<!--订单任务页面-->
								<div v-for="(data,dataIndex) in fOrderTask" :key="'5'+dataIndex"
									class="task-card-item flex-row-space-between" >
									<!-- 左侧 -->
									<div class="">
										<div class="icon-box radius5 flex-row-center-center icon-bg-blue">
											<div class="iconfont icon-fahuo font-color-fff font-size24"></div>
										</div>
									</div>
									<!-- 右侧 -->
									<div class="task-card-item-content flex-colum-between">
										<!-- 上半部分 -->
										<div class="flex-row-space-between">
											<!-- 上半部分左侧 -->
											<div class="">
												<!-- 标题 -->
												<div class="font-size15 font-weight700">
													{{data.fTitle}}
												</div>
												<!-- 状态 -->
												<div class="flex-row font-size12 margin-tb-4 flex-row-align-center">
													<div
														class="task-card-item-tag radius2 theme-border-color font-color-fff theme-bg-color margin-r-8">
														{{data.fTaskTypeName}}
													</div>
												</div>
											</div>
											<!-- 上半部分右侧 -->
											<div class="font-size14 flex-column-start-end">
												<!-- 功能那个按钮 -->
												<div class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-blue-white pointer"
													@click="makeOrderTask(data.fOrderTaskID,data.fGoodsID,data.fPointAmount)">
													做任务
												</div>
											</div>
										</div>
										<!-- 下半部分 -->
										<div class="font-size13 font-color-999"
											:class="{'margin-b-15':dataIndex == fOrderTask.length - 1}">
											<!-- 有效日期 -->
											<div class="flex-row-align-center margin-tb-4">
												<span class="iconfont icon-shijian1 font-size15 margin-r-8"></span>
												有效日期：{{data.fBeginDate}} 至 {{data.fEndDate}}
											</div>
											<!-- 任务状态 0~n+1 -->
											<!-- 如果有商品名称就是指定商品发货任务 -->
											<div v-if="data.fGoodsNames != ''"
												v-for="(item,index) in data.fGoodsNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 如果有仓库就是仓库发货任务 -->
											<div v-if="data.fDepotNames != ''"
												v-for="(item,index) in data.fDepotNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 提货方式 -->
											<div class="delivery-method" v-if="data.fDeliverys">
												<img class="delivery-img" src="@/assets/提货.png" alt="">
												<span>{{data.fDeliverys}}</span>
											</div>
											<!-- 进度条 （很复杂 最后做） -->
											<div class="task-card-item-progress margin-tb-10">
												<div class="normal-line radius20 bg-F2F2F2"></div>
												<div style="margin-left: 96.5%;top:25px"
													class="normal-line-dot bg-F2F2F2 radius20 flex-row-center-center">
													<div
														class="iconfont icon-wujiaoxingxingxingshoucangmoren font-color-fff">
													</div>
													<div
														class="normal-text-top font-size13 flex-row flex-space-center font-color-999">
								
														{{data.fOrderAmount+'吨'}}
													</div>
													<div
														class="normal-text-bottom font-size13 flex-row flex-space-center ">
														{{data.fPointAmount}}积分
													</div>
												</div>
												<div v-if="data.fTaskTypeName == '常规订单任务'"
													style="margin-top: 35px;color: #ff6600 !important">
													领取任务即获取奖励，未支付订单视同未完成任务，订单退款不退奖励积分</div>
												<div v-if="data.fTaskTypeName == '首次发货订单任务'"
													style="margin-top: 35px;color: #ff6600 !important">
													首次在店铺下单即可获取奖励，未支付订单视同未完成任务，订单退款不退奖励积分</div>
											</div>
											<el-divider v-if="dataIndex != fOrderTask.length - 1"></el-divider>
										</div>
									</div>
								</div>
								<div v-for="(data,dataIndex) in taskMarket" :key="'1'+dataIndex"
									class="task-card-item flex-row-space-between" >
									<!-- 左侧 -->
									<div class="">
										<div class="icon-box radius5 flex-row-center-center icon-bg-blue">
											<!--  :class="{'icon-bg-blue':data.taskName == '发货任务','icon-bg-orange':data.taskName == '充值任务','icon-bg-red':data.taskName == '下单任务'}" -->
											<div class="iconfont icon-fahuo font-color-fff font-size24"></div>
											<!--  v-if="data.taskName == '发货任务'" -->
											<!-- <div v-if="data.taskName == '充值任务'" class="iconfont icon-chongzhi font-color-fff font-size24"></div>
										<div v-if="data.taskName == '下单任务'" class="iconfont icon-xiadan1 font-color-fff font-size24"></div> -->
										</div>
									</div>
									<!-- 右侧 -->
									<div class="task-card-item-content flex-colum-between">
										<!-- 上半部分 -->
										<div class="flex-row-space-between">
											<!-- 上半部分左侧 -->
											<div class="">
												<!-- 标题 -->
												<div class="font-size15 font-weight700">
													{{data.fTitle}}
												</div>
												<!-- 积分 -->
												<!-- 	<div class="font-size13 font-color-999 flex-row-align-center margin-tb-4">
												<span class="shoucang-one iconfont icon-shoucang font-size15 font-color-FF9900 margin-r-6"></span>
												最高奖励{{data.fSumPointAmount}} 积分
											</div> -->
												<!-- 状态 -->
												<div class="flex-row font-size12 margin-tb-4 flex-row-align-center">
													<div
														class="task-card-item-tag radius2 theme-border-color font-color-fff theme-bg-color margin-r-8">
														{{data.fTaskTypeName}}
													</div>
													<!-- <div v-if="data.type2 != ''" class="task-card-item-tag radius2 theme-border-color theme-text-color">
													{{data.type2}}
												</div> -->
													<!-- <div v-if="data.share" class="text-ff6600">
													<span class="iconfont icon-arrow- margin-l-10 margin-r-2"></span>分享得积分
												</div> -->
												</div>
											</div>
											<!-- 上半部分右侧 -->
											<div class="font-size14 flex-column-start-end">
												<!-- 功能那个按钮 -->
												<div class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-blue-white pointer"
													@click="pickUpTheTask(data.fDeliverTaskID, data)">
													{{ data.fTaskTypeName == '商品任务' ? '做任务' : '领任务' }}
												</div>
												<!-- 完成状态 -->
												<!-- <div v-if="data.progress != ''" class="task-card-item-progress-text margin-t-15 font-size14 font-color-999">
												已完成<span class="theme-text-color">{{data.progress}}/{{data.fSumNeedAmount}}</span>
											</div> -->
											</div>
										</div>
										<!-- 下半部分 -->
										<div class="font-size13 font-color-999"
											:class="{'margin-b-15':dataIndex == taskMarket.length - 1}">
											<!-- 有效日期 -->
											<div class="flex-row-align-center margin-tb-4">
												<span class="iconfont icon-shijian1 font-size15 margin-r-8"></span>
												有效日期：{{data.fBeginDate}} 至 {{data.fEndDate}}
											</div>
											<!-- 任务状态 0~n+1 -->
											<!-- 如果有商品名称就是指定商品发货任务 -->
											<div v-if="data.fGoodsNames != ''"
												v-for="(item,index) in data.fGoodsNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 如果有仓库就是仓库发货任务 -->
											<div v-if="data.fDepotNames != ''"
												v-for="(item,index) in data.fDepotNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>

											<!-- 提货方式 -->
											<div class="delivery-method" v-if="data.fDeliverys">
												<img class="delivery-img" src="@/assets/提货.png" alt="">
												<span>{{data.fDeliverys}}</span>
											</div>

											<!-- 进度条 （很复杂 最后做） -->
											<div class="task-card-item-progress margin-tb-10">
												<div class="normal-line radius20 bg-F2F2F2"></div>
												<!-- <div class="active-line radius20 theme-bg-color" :style="{width: countProgress(data.fSortNO,data.details.length)+'px'}"> -->
												<!-- 为避免已完成进度和进度点碰撞后重叠 废弃已完成进度提示 -->
												<!-- <span v-if="data.num != 0" class="active-text font-size13 flex-row theme-text-color flex-space-center">
													{{data.type == '充值任务' ? '￥'+data.num : data.num+'吨'}}
												</span> -->
												<!-- </div> -->
												<!-- bg-fff font-color-FF9900 -->
												<div v-for="(item,index) in data.details" :key="'3'+index"
													class="normal-line-dot bg-F2F2F2 radius20 flex-row-center-center"
													:style="{marginLeft:  schedulelength(item.fNeedAmount,data)+'px',top:25-index*3.5+'px'}">
													<div
														class="iconfont icon-wujiaoxingxingxingshoucangmoren font-color-fff">
													</div>
													<div class="normal-text-top font-size13 font-color-999 flex-row flex-space-center"  
														:class="{'text-333': countProgress(item.fSortNO,data.details.length) <= countProgress(data.fSortNO,data.details.length) }">
														<!-- {{data.taskName == '充值任务' ? '￥'+item.num : item.num+'吨'}} -->
														<!-- <span v-if="item.check"  v-bind:style="{ 'margin-left': (index)*105 + 'px' }" style="width:75px;" >{{item.fNeedAmount+'吨'}}</span> -->
                            <span >{{item.fNeedAmount+'吨'}}</span>
                            <span
															v-if="data.fIsOpenTaskName == '台段任务' && index == 0"
															style="color: #ff6600 !important;">(基础台段)</span>
													</div>
                          <!-- <div class="normal-text-top font-size13 flex-row flex-space-center font-color-999" 
														:class="{'text-333': countProgress(item.fSortNO,data.details.length) <= countProgress(data.fSortNO,data.details.length) }">
														<span style="display:block;width:55px;">110炖</span><span
															v-if="data.fIsOpenTaskName == '台段任务' && index == 0"
															style="color: #ff6600 !important;">(基础台段)</span>
													</div> -->
													<!-- <div class="normal-text-bottom font-size13 flex-row flex-space-center"
														v-if="data.fIsOpenTask == 0"
														:class="{'text-ff6600': countProgress(item.fSortNO,data.details.length) <= countProgress(data.fSortNO,data.details.length) }">
														{{item.fPoint}}积分/吨
													</div> -->
													<div class="normal-text-bottom font-size13 flex-row flex-space-center"
														
														:class="{'text-ff6600': countProgress(item.fSortNO,data.details.length) <= countProgress(data.fSortNO,data.details.length) }">
                            <!-- <span v-if="item.check" v-bind:style="{ 'margin-left': (index)*95 + 'px', 'margin-right' :index==0 ? '40px': '0px'}" style="width:110px;text-align: left;">{{ (data.fIsOpenTaskName == '开口任务' || data.fIsOpenTaskName == '台段任务') ?  item.fPoint + '积分/吨' : item.fPoint + '积分' }}</span> -->
                            <span  >{{ (data.fIsOpenTaskName == '开口任务' || data.fIsOpenTaskName == '台段任务') ?  item.fPoint + '积分/吨' : item.fPoint + '积分' }}</span>
														<!-- {{ (data.fIsOpenTaskName == '开口任务' || data.fIsOpenTaskName == '台段任务') ?  item.fPoint + '积分/吨' : item.fPoint + '积分' }} -->
													</div>
												</div>
												<!-- <div v-if="data.fIsOpenTaskName == '台段任务'" class="" style="margin-top: 20px;color: #ff6600 !important">基础台段</div> -->
												<div v-if="data.fIsOpenTaskName == '开口任务'"
													style="margin-top: 35px;color: #ff6600 !important"> 奖励积分=实际量*积分奖励/吨
													<span>，每个任务只能领取一次奖励</span>
												</div>
												<div v-if="data.fIsOpenTaskName == '闭口任务'"
													style="margin-top: 35px;color: #ff6600 !important"> 完成任务后总额积分奖励
													<span>，每个任务只能领取一次奖励</span>
												</div>
												<div v-if="data.fIsOpenTaskName == '台段任务'"
													style="margin-top: 35px;color: #ff6600 !important">
													奖励积分=(实际量-基础值）*区间奖励
													<span>，每个任务只能领取一次奖励</span>
												</div>
											</div>
											<el-divider v-if="dataIndex != taskMarket.length - 1"></el-divider>
										</div>
									</div>
								</div>
								<!--充值任务页面-->
								<div v-for="(data,dataIndex) in fGatherTask" :key="'6'+dataIndex"
									class="task-card-item flex-row-space-between">
									<!-- 左侧 -->
									<div class="">
										<div class="icon-box radius5 flex-row-center-center icon-bg-blue">
											<div class="iconfont icon-fahuo font-color-fff font-size24"></div>
										</div>
									</div>
									<!-- 右侧 -->
									<div class="task-card-item-content flex-colum-between">
										<!-- 上半部分 -->
										<div class="flex-row-space-between">
											<!-- 上半部分左侧 -->
											<div class="">
												<!-- 标题 -->
												<div class="font-size15 font-weight700">
													{{data.fTitle}}
												</div>
												<!-- 状态 -->
												<div class="flex-row font-size12 margin-tb-4 flex-row-align-center">
													<div
														class="task-card-item-tag radius2 theme-border-color font-color-fff theme-bg-color margin-r-8">
														{{data.fActivityName}}
													</div>
												</div>
											</div>
											<!-- 上半部分右侧 -->
											<div class="font-size14 flex-column-start-end">
												<!-- 功能那个按钮 -->
												<div class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-blue-white pointer"
													@click="makeRechargeOrder(data.fGatherTaskID,data.fGatheringMoney)">
													做任务
												</div>
											</div>
										</div>
										<!-- 下半部分 -->
										<div class="font-size13 font-color-999"
											:class="{'margin-b-15':dataIndex == fGatherTask.length - 1}">
											<!-- 有效日期 -->
											<div class="flex-row-align-center margin-tb-4">
												<span class="iconfont icon-shijian1 font-size15 margin-r-8"></span>
												有效时间：{{ data.fBeginDate }} 至 {{ data.fEndDate }}
											</div>
											<!-- 任务状态 0~n+1 -->
											<!-- 如果有商品名称就是指定商品发货任务 -->
											<div v-if="data.fGoodsNames != ''"
												v-for="(item,index) in data.fGoodsNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 如果有仓库就是仓库发货任务 -->
											<div v-if="data.fDepotNames != ''"
												v-for="(item,index) in data.fDepotNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 提货方式 -->
											<div class="delivery-method" v-if="data.fDeliverys">
												<img class="delivery-img" src="@/assets/提货.png" alt="">
												<span>{{data.fDeliverys}}</span>
											</div>
											<!-- 进度条 （很复杂 最后做） -->
											<div class="task-card-item-progress margin-tb-10">
												<div class="normal-line radius20 bg-F2F2F2"></div>
												<div class="normal-line-dot bg-F2F2F2 radius20 flex-row-center-center"
													style="margin-left: 96.5%;top:25px">
													<div class="iconfont icon-wujiaoxingxingxingshoucangmoren font-color-fff"
														style="">
													</div>
													<div
														class="normal-text-top font-size13 flex-row flex-space-center font-color-999">

														{{data.fGatheringMoney+'元'}}
													</div>
													<div
														class="normal-text-bottom font-size13 flex-row flex-space-center ">
														{{data.fPointAmount}}积分
													</div>
												</div>
												<div style="margin-top: 35px;color: #ff6600 !important">
													完成充值任务即可获取奖励，充值金额未实现出厂前为冻结状态，冻结金额=充值金额-出厂金额</div>
											</div>
											<el-divider v-if="dataIndex != fGatherTask.length - 1"></el-divider>
										</div>
									</div>
								</div>
							</div>
							<!--订单任务页面-->
							<div v-if="activeName == 'fOrderTask'">
								<div v-for="(data,dataIndex) in fOrderTask" :key="'5'+dataIndex"
									class="task-card-item flex-row-space-between">
									<!-- 左侧 -->
									<div class="">
										<div class="icon-box radius5 flex-row-center-center icon-bg-blue">
											<div class="iconfont icon-fahuo font-color-fff font-size24"></div>
										</div>
									</div>
									<!-- 右侧 -->
									<div class="task-card-item-content flex-colum-between">
										<!-- 上半部分 -->
										<div class="flex-row-space-between">
											<!-- 上半部分左侧 -->
											<div class="">
												<!-- 标题 -->
												<div class="font-size15 font-weight700">
													{{data.fTitle}}
												</div>
												<!-- 状态 -->
												<div class="flex-row font-size12 margin-tb-4 flex-row-align-center">
													<div
														class="task-card-item-tag radius2 theme-border-color font-color-fff theme-bg-color margin-r-8">
														{{data.fTaskTypeName}}
													</div>
												</div>
											</div>
											<!-- 上半部分右侧 -->
											<div class="font-size14 flex-column-start-end">
												<!-- 功能那个按钮 -->
												<div class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-blue-white pointer"
													@click="makeOrderTask(data.fOrderTaskID,data.fGoodsID,data.fPointAmount)">
													做任务
												</div>
											</div>
										</div>
										<!-- 下半部分 -->
										<div class="font-size13 font-color-999"
											:class="{'margin-b-15':dataIndex == fOrderTask.length - 1}">
											<!-- 有效日期 -->
											<div class="flex-row-align-center margin-tb-4">
												<span class="iconfont icon-shijian1 font-size15 margin-r-8"></span>
												有效日期：{{data.fBeginDate}} 至 {{data.fEndDate}}
											</div>
											<!-- 任务状态 0~n+1 -->
											<!-- 如果有商品名称就是指定商品发货任务 -->
											<div v-if="data.fGoodsNames != ''"
												v-for="(item,index) in data.fGoodsNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 如果有仓库就是仓库发货任务 -->
											<div v-if="data.fDepotNames != ''"
												v-for="(item,index) in data.fDepotNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 提货方式 -->
											<div class="delivery-method" v-if="data.fDeliverys">
												<img class="delivery-img" src="@/assets/提货.png" alt="">
												<span>{{data.fDeliverys}}</span>
											</div>
											<!-- 进度条 （很复杂 最后做） -->
											<div class="task-card-item-progress margin-tb-10">
												<div class="normal-line radius20 bg-F2F2F2"></div>
												<div style="margin-left: 96.5%;top:25px"
													class="normal-line-dot bg-F2F2F2 radius20 flex-row-center-center">
													<div
														class="iconfont icon-wujiaoxingxingxingshoucangmoren font-color-fff">
													</div>
													<div
														class="normal-text-top font-size13 flex-row flex-space-center font-color-999">
							
														{{data.fOrderAmount+'吨'}}
													</div>
													<div
														class="normal-text-bottom font-size13 flex-row flex-space-center ">
														{{data.fPointAmount}}积分
													</div>
												</div>
												<div v-if="data.fTaskTypeName == '常规订单任务'"
													style="margin-top: 35px;color: #ff6600 !important">
													领取任务即获取奖励，未支付订单视同未完成任务，订单退款不退奖励积分</div>
												<div v-if="data.fTaskTypeName == '首次发货订单任务'"
													style="margin-top: 35px;color: #ff6600 !important">
													首次在店铺下单即可获取奖励，未支付订单视同未完成任务，订单退款不退奖励积分</div>
											</div>
											<el-divider v-if="dataIndex != fOrderTask.length - 1"></el-divider>
										</div>
									</div>
								</div>
							</div>
							<!-- 提货任务 -->
							<div v-if="activeName == 'fDeliverTask'">
								<!--提货任务页面-->
								<div v-for="(data,dataIndex) in taskMarket" :key="'5'+dataIndex"
									class="task-card-item flex-row-space-between">
									<!-- 左侧 -->
									<div class="">
										<div class="icon-box radius5 flex-row-center-center icon-bg-blue">
											<div class="iconfont icon-fahuo font-color-fff font-size24"></div>
										</div>
									</div>
									<!-- 右侧 -->
									<div class="task-card-item-content flex-colum-between">
										<!-- 上半部分 -->
										<div class="flex-row-space-between">
											<!-- 上半部分左侧 -->
											<div class="">
												<!-- 标题 -->
												<div class="font-size15 font-weight700">
													{{data.fTitle}}
												</div>
												<!-- 状态 -->
												<div class="flex-row font-size12 margin-tb-4 flex-row-align-center">
													<div
														class="task-card-item-tag radius2 theme-border-color font-color-fff theme-bg-color margin-r-8">
														{{data.fTaskTypeName}}
													</div>
												</div>
											</div>
											<!-- 上半部分右侧 -->
											<div class="font-size14 flex-column-start-end">
												<!-- 功能那个按钮 -->
												<div class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-blue-white pointer"
													@click="pickUpTheTask(data.fDeliverTaskID, data)">
													{{ data.fTaskTypeName == '商品任务' ? '做任务' : '领任务' }}
												</div>
											</div>
										</div>
										<!-- 下半部分 -->
										<div class="font-size13 font-color-999"
											:class="{'margin-b-15':dataIndex == taskMarket.length - 1}">
											<!-- 有效日期 -->
											<div class="flex-row-align-center margin-tb-4">
												<span class="iconfont icon-shijian1 font-size15 margin-r-8"></span>
												有效日期：{{data.fBeginDate}} 至 {{data.fEndDate}}
											</div>
											<!-- 任务状态 0~n+1 -->
											<!-- 如果有商品名称就是指定商品发货任务 -->
											<div v-if="data.fGoodsNames != ''"
												v-for="(item,index) in data.fGoodsNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 如果有仓库就是仓库发货任务 -->
											<div v-if="data.fDepotNames != ''"
												v-for="(item,index) in data.fDepotNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 提货方式 -->
											<div class="delivery-method" v-if="data.fDeliverys">
												<img class="delivery-img" src="@/assets/提货.png" alt="">
												<span>{{data.fDeliverys}}</span>
											</div>
											<!-- 进度条 （很复杂 最后做） -->
											<div class="task-card-item-progress margin-tb-10">
												<div class="normal-line radius20 bg-F2F2F2"></div>
												<!-- <div class="active-line radius20 theme-bg-color" :style="{width: countProgress(data.fSortNO,data.details.length)+'px'}"> -->
												<!-- 为避免已完成进度和进度点碰撞后重叠 废弃已完成进度提示 -->
												<!-- <span v-if="data.num != 0" class="active-text font-size13 flex-row theme-text-color flex-space-center">
													{{data.type == '充值任务' ? '￥'+data.num : data.num+'吨'}}
												</span> -->
												<!-- </div> -->
												<!-- bg-fff font-color-FF9900 -->
												<div v-for="(item,index) in data.details" :key="'3'+index"
													class="normal-line-dot bg-F2F2F2 radius20 flex-row-center-center"
													:style="{marginLeft:  schedulelength(item.fNeedAmount,data)+'px',top:25-index*3.5+'px'}">
													<div
														class="iconfont icon-wujiaoxingxingxingshoucangmoren font-color-fff">
													</div>
													<div class="normal-text-top font-size13 flex-row flex-space-center font-color-999"
														:class="{'text-333': countProgress(item.fSortNO,data.details.length) <= countProgress(data.fSortNO,data.details.length) }">
														<!-- {{data.taskName == '充值任务' ? '￥'+item.num : item.num+'吨'}} -->
														{{item.fNeedAmount+'吨'}}<span
															v-if="data.fIsOpenTaskName == '台段任务' && index == 0"
															style="color: #ff6600 !important;">(基础台段)</span>
													</div>
													<!-- <div class="normal-text-bottom font-size13 flex-row flex-space-center"
														v-if="data.fIsOpenTask == 0"
														:class="{'text-ff6600': countProgress(item.fSortNO,data.details.length) <= countProgress(data.fSortNO,data.details.length) }">
														{{item.fPoint}}积分/吨
													</div> -->
													<div class="normal-text-bottom font-size13 flex-row flex-space-center"
														
														:class="{'text-ff6600': countProgress(item.fSortNO,data.details.length) <= countProgress(data.fSortNO,data.details.length) }">
														{{ (data.fIsOpenTaskName == '开口任务' || data.fIsOpenTaskName == '台段任务') ?  item.fPoint + '积分/吨' : item.fPoint + '积分' }}
													</div>
												</div>
												<!-- <div v-if="data.fIsOpenTaskName == '台段任务'" class="" style="margin-top: 20px;color: #ff6600 !important">基础台段</div> -->
												<div v-if="data.fIsOpenTaskName == '开口任务'"
													style="margin-top: 35px;color: #ff6600 !important"> 奖励积分=实际量*积分奖励/吨
													<span>，每个任务只能领取一次奖励</span>
												</div>
												<div v-if="data.fIsOpenTaskName == '闭口任务'"
													style="margin-top: 35px;color: #ff6600 !important"> 完成任务后总额积分奖励
													<span>，每个任务只能领取一次奖励</span>
												</div>
												<div v-if="data.fIsOpenTaskName == '台段任务'"
													style="margin-top: 35px;color: #ff6600 !important">
													奖励积分=(实际量-基础值）*区间奖励
													<span>，每个任务只能领取一次奖励</span>
												</div>
											</div>
											<el-divider v-if="dataIndex != taskMarket.length - 1"></el-divider>
										</div>
									</div>
								</div>
							</div>
							<!--充值任务页面-->
							<div v-if="activeName == 'fGatherTask'">
								<div v-for="(data,dataIndex) in fGatherTask" :key="'6'+dataIndex"
									class="task-card-item flex-row-space-between">
									<!-- 左侧 -->
									<div class="">
										<div class="icon-box radius5 flex-row-center-center icon-bg-blue">
											<div class="iconfont icon-fahuo font-color-fff font-size24"></div>
										</div>
									</div>
									<!-- 右侧 -->
									<div class="task-card-item-content flex-colum-between">
										<!-- 上半部分 -->
										<div class="flex-row-space-between">
											<!-- 上半部分左侧 -->
											<div class="">
												<!-- 标题 -->
												<div class="font-size15 font-weight700">
													{{data.fTitle}}
												</div>
												<!-- 状态 -->
												<div class="flex-row font-size12 margin-tb-4 flex-row-align-center">
													<div
														class="task-card-item-tag radius2 theme-border-color font-color-fff theme-bg-color margin-r-8">
														{{data.fActivityName}}
													</div>
												</div>
											</div>
											<!-- 上半部分右侧 -->
											<div class="font-size14 flex-column-start-end">
												<!-- 功能那个按钮 -->
												<div class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-blue-white pointer"
													@click="makeRechargeOrder(data.fGatherTaskID,data.fGatheringMoney)">
													做任务
												</div>
											</div>
										</div>
										<!-- 下半部分 -->
										<div class="font-size13 font-color-999"
											:class="{'margin-b-15':dataIndex == fGatherTask.length - 1}">
											<!-- 有效日期 -->
											<div class="flex-row-align-center margin-tb-4">
												<span class="iconfont icon-shijian1 font-size15 margin-r-8"></span>
												有效时间：{{ data.fBeginDate }} 至 {{ data.fEndDate }}
											</div>
											<!-- 任务状态 0~n+1 -->
											<!-- 如果有商品名称就是指定商品发货任务 -->
											<div v-if="data.fGoodsNames != ''"
												v-for="(item,index) in data.fGoodsNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 如果有仓库就是仓库发货任务 -->
											<div v-if="data.fDepotNames != ''"
												v-for="(item,index) in data.fDepotNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 提货方式 -->
											<div class="delivery-method" v-if="data.fDeliverys">
												<img class="delivery-img" src="@/assets/提货.png" alt="">
												<span>{{data.fDeliverys}}</span>
											</div>
											<!-- 进度条 （很复杂 最后做） -->
											<div class="task-card-item-progress margin-tb-10">
												<div class="normal-line radius20 bg-F2F2F2"></div>
												<div class="normal-line-dot bg-F2F2F2 radius20 flex-row-center-center"
													style="margin-left: 96.5%;top:25px">
													<div class="iconfont icon-wujiaoxingxingxingshoucangmoren font-color-fff"
														style="">
													</div>
													<div
														class="normal-text-top font-size13 flex-row flex-space-center font-color-999">

														{{data.fGatheringMoney+'元'}}
													</div>
													<div
														class="normal-text-bottom font-size13 flex-row flex-space-center ">
														{{data.fPointAmount}}积分
													</div>
												</div>
												<div style="margin-top: 35px;color: #ff6600 !important">
													完成充值任务即可获取奖励，充值金额未实现出厂前为冻结状态，冻结金额=充值金额-出厂金额</div>
											</div>
											<el-divider v-if="dataIndex != fGatherTask.length - 1"></el-divider>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-if="tabActive == 2">
							<div v-if="activeName == 'total'">
								<!--订单任务页面-->
								<div v-for="(data,dataIndex) in fOrderTaskMy" :key="'5'+dataIndex"
									class="task-card-item flex-row-space-between">
									<!-- 左侧 -->
									<div class="">
										<div class="icon-box radius5 flex-row-center-center icon-bg-blue">
											<div class="iconfont icon-fahuo font-color-fff font-size24"></div>
										</div>
									</div>
									<!-- 右侧 -->
									<div class="task-card-item-content flex-colum-between">
										<!-- 上半部分 -->
										<div class="flex-row-space-between">
											<!-- 上半部分左侧 -->
											<div class="flex-row" style="align-items: center;">
												<div>
													<!-- 标题 -->
													<div class="font-size15 font-weight700">
														{{data.fTitle}}
													</div>
													<!-- 状态 -->
													<div class="flex-row font-size12 margin-tb-4 flex-row-align-center">
														<div
															class="task-card-item-tag radius2 theme-border-color font-color-fff theme-bg-color margin-r-8">
															{{data.fTaskTypeName}}
														</div>
													</div>
												</div>
												<div v-if="data.fRecord.fStatus == 0 || data.fRecord.fStatus ==1"
													class="margin-l-10 font-size12 font-color-FF0000 border-FF0000 padding-lr-5 radius2"
													style="width:fit-content;height: fit-content;">
													{{data.fRecord.fStatus == 1?"已完成":"未完成"}}
												</div>
											</div>
											<!-- 上半部分右侧 -->
											<div v-if='false' class="font-size14 flex-column-start-end">
												<!-- 功能那个按钮 -->
												<div class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-blue-white pointer"
													@click="makeOrderTask(data.fOrderTaskID,data.fGoodsID,data.fPointAmount,data.fRecord.fOrderID)">
													做任务
												</div>
											</div>
										</div>
										<!-- 下半部分 -->
										<div class="font-size13 font-color-999"
											:class="{'margin-b-15':dataIndex == fOrderTaskMy.length - 1}">
											<!-- 有效日期 -->
											<div class="flex-row-space-between-center margin-tb-4">
												<div>
													<span class="iconfont icon-shijian1 font-size15 margin-r-8"></span>
													有效日期：{{data.fBeginDate}} 至 {{data.fEndDate}}
												</div>
												<div v-if="data.fCanDelete == 1"
													class="pointer margin-r-10 delete-hover font-size15"
													@click="deleteTaskorder(data)"><i class="el-icon-delete"></i></div>
											</div>
											<!-- 任务状态 0~n+1 -->
											<!-- 如果有商品名称就是指定商品发货任务 -->
											<div v-if="data.fGoodsNames != ''"
												v-for="(item,index) in data.fGoodsNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 如果有仓库就是仓库发货任务 -->
											<div v-if="data.fDepotNames != ''"
												v-for="(item,index) in data.fDepotNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 提货方式 -->
											<div class="delivery-method" v-if="data.fDeliverys">
												<img class="delivery-img" src="@/assets/提货.png" alt="">
												<span>{{data.fDeliverys}}</span>
											</div>
											<!-- 进度条 （很复杂 最后做） -->
											<div class="task-card-item-progress margin-tb-10">
												<div v-if='data.fRecord.fStatus == 0'
													class="normal-line radius20 bg-F2F2F2"></div>
												<div v-else class="normal-line radius20 bg-F2F2F2 icon-bg-blue"></div>
												<div style="margin-left: 96.5%;top:25px"
													:class='data.fRecord.fStatus == 0 ? `normal-line-dot bg-F2F2F2 radius20 flex-row-center-center`: `icon-bg-FF9900  normal-line-dot bg-F2F2F2 radius20 flex-row-center-center`'>
													<div
														class="iconfont icon-wujiaoxingxingxingshoucangmoren font-color-fff">
													</div>
													<div
														class="normal-text-top font-size13 flex-row flex-space-center font-color-999">
								
														{{data.fOrderAmount+'吨'}}
													</div>
													<div
														class="normal-text-bottom font-size13 flex-row flex-space-center">
														{{data.fPointAmount}}积分
													</div>
												</div>
												<div v-if="data.fTaskTypeName == '常规订单任务'"
													style="margin-top: 35px;color: #ff6600 !important">
													领取任务即获取奖励，未支付订单视同未完成任务，订单退款不退奖励积分</div>
												<div v-if="data.fTaskTypeName == '首次发货订单任务'"
													style="margin-top: 35px;color: #ff6600 !important">
													首次在店铺下单即可获取奖励，未支付订单视同未完成任务，订单退款不退奖励积分</div>
											</div>
										</div>
										<el-divider v-if="dataIndex != fOrderTaskMy.length - 1"></el-divider>
									</div>
								</div>
								<div v-for="(data,dataIndex) in taskMy" :key="'1'+dataIndex"
									class="task-card-item padding-lr-13 flex-row-space-between">
									<!-- 左侧 -->
									<div class="">
										<div class="icon-box radius5 flex-row-center-center icon-bg-blue">
											<!--  :class="{'icon-bg-blue':data.taskName == '发货任务','icon-bg-orange':data.taskName == '充值任务','icon-bg-red':data.taskName == '下单任务'}" -->
											<div class="iconfont icon-fahuo font-color-fff font-size24"></div>
											<!--  v-if="data.taskName == '发货任务'" -->
											<!-- <div v-if="data.taskName == '充值任务'" class="iconfont icon-chongzhi font-color-fff font-size24"></div>
												<div v-if="data.taskName == '下单任务'" class="iconfont icon-xiadan1 font-color-fff font-size24"></div> -->
										</div>
									</div>
									<!-- 右侧 -->
									<div class="task-card-item-content flex-colum-between">
										<!-- 上半部分 -->
										<div class="flex-row-space-between">
											<!-- 上半部分左侧 -->
											<div class="flex-row">
												<div>
													<!-- 标题 -->
													<div class="font-size15 font-weight700">
														{{data.fTitle}}
													</div>
													<!-- 积分 -->
													<!-- 		<div class="font-size13 font-color-999 flex-row-align-center margin-tb-4">
															<span class="shoucang-one iconfont icon-shoucang font-size15 font-color-FF9900 margin-r-6"></span>
															最高奖励{{data.fSumPointAmount}} 积分
														</div> -->
													<!-- 状态 -->
													<div class="flex-row font-size12 margin-tb-4 flex-row-align-center">
														<div
															class="task-card-item-tag radius2 theme-border-color font-color-fff theme-bg-color margin-r-8">
															{{data.fTaskTypeName}}
														</div>
														<!-- <div v-if="data.type2 != ''" class="task-card-item-tag radius2 theme-border-color theme-text-color">
																{{data.type2}}
															</div> -->
														<!-- <div v-if="data.share" class="text-ff6600">
																<span class="iconfont icon-arrow- margin-l-10 margin-r-2"></span>分享得积分
															</div> -->
													</div>
												</div>
												<div v-if="data.records.fStatus == 1 || data.records.fStatus ==2"
													class="margin-l-10 font-size12 font-color-FF0000 border-FF0000 padding-lr-5 radius2"
													style="width:fit-content;height: fit-content;">
													{{data.records.fStatus == 1?"已完成":"未完成"}}
												</div>
											</div>
											<!-- 上半部分右侧 -->
											<div class="font-size13 flex-column-start-end">
												<!-- 功能那个按钮 -->
												<!-- fStatus：0初始1已完成2未完成 -->
												<!--按照最新要求，去做任务，暂不显示(2022/5/19)-->
												<div v-if="false" style="cursor: pointer;"
													class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-h-30"
													@click="goShoplist"
													:class="{'task-card-item-btn-blue-white':data.records.fStatus == 0,'task-card-item-btn-orange-white':data.records.fStatus == 1,'task-card-item-btn-white-blue task-card-item-btn-h-28':data.records.fStatus == 2}">
													去做任务
												</div>
												<!-- <div v-if="data.records.fStatus == 1"  class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-h-30"
													 :class="{'task-card-item-btn-blue-white':data.records.fStatus == 0,'task-card-item-btn-orange-white':data.records.fStatus == 1,'task-card-item-btn-white-blue task-card-item-btn-h-28':data.records.fStatus == 2}">
														已完成
													</div> -->
												<!-- data.records.fEnableReceivePointStatus==1 -->
												<div v-if="data.records.fEnableReceivePointStatus==1"
													style="cursor: pointer;user-select: none;"
													class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-h-30"
													@click="getTask(data.records)">
													领取奖励
												</div>
												<!-- <div v-if="data.records.fStatus == 2" class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-h-30"
													 :class="{'task-card-item-btn-blue-white':data.records.fStatus == 0,'task-card-item-btn-orange-white':data.records.fStatus == 1,'task-card-item-btn-white-blue task-card-item-btn-h-28':data.records.fStatus == 2}">
														未完成
													</div> -->
												<!-- 完成状态 -->
												<div
													class="task-card-item-progress-text margin-t-15 font-size14 font-color-999">
													已完成<span
														class="theme-text-color">{{data.records.fFinishAmount}}/{{data.records.fNeedAmount}}</span>
												</div>
											</div>
										</div>
										<!-- 下半部分 -->
										<div class="font-size13 font-color-999">
											<!-- 有效日期 -->
											<div class="flex-row-space-between-center margin-tb-4">
												<div>
													<span class="iconfont icon-shijian1 font-size15 margin-r-8"></span>
													有效日期：{{data.fBeginDate}} 至 {{data.fEndDate}}
												</div>
												<div v-if="data.fCanDelete == 1"
													class="pointer margin-r-10 delete-hover font-size15"
													@click="deleteTask(data)"><i class="el-icon-delete"></i></div>
											</div>
											<!-- 任务状态 0~n+1 -->
											<!-- 如果有商品名称就是指定商品发货任务 -->
											<div v-if="data.fGoodsNames != ''"
												v-for="(item,index) in data.fGoodsNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 如果有仓库就是仓库发货任务 -->
											<div v-if="data.fDepotNames != ''"
												v-for="(item,index) in data.fDepotNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 提货方式 -->
											<div class="delivery-method" v-if="data.fDeliverys">
												<img class="delivery-img" src="@/assets/提货.png" alt="">
												<span>{{data.fDeliverys}}</span>
											</div>
											<!-- 进度条 （很复杂 最后做） -->
											<div class="task-card-item-progress margin-tb-10">
												<div class="normal-line radius20 bg-F2F2F2"></div>
												<div class="active-line radius20 theme-bg-color"
													:style="{width: countProgress(data.records.fFinishAmount,data.fSumNeedAmount)+'px'}">
													<!-- 为避免已完成进度和进度点碰撞后重叠 废弃已完成进度提示 -->
													<!-- <span v-if="data.num != 0" class="active-text font-size13 flex-row theme-text-color flex-space-center">
															{{data.type == '充值任务' ? '￥'+data.num : data.num+'吨'}}
														</span> -->
												</div>
												<!-- bg-fff font-color-FF9900 -->
												<div v-for="(item,index) in data.details" :key="'3'+index"
													class="normal-line-dot bg-F2F2F2 radius20 flex-row-center-center"
													:class="{'bg-FF9900': countProgress(item.fNeedAmount,data.fSumNeedAmount) <= countProgress(data.records.fFinishAmount,data.fSumNeedAmount) }"
													:style="{marginLeft:  schedulelength(item.fNeedAmount,data)+'px',top:25-index*3.5+'px'}">
													<div
														class="iconfont icon-wujiaoxingxingxingshoucangmoren font-color-fff">
													</div>
													<div class="normal-text-top font-size13 flex-row flex-space-center font-color-999"
														:class="{'text-333': countProgress(item.fNeedAmount,data.fSumNeedAmount) <= countProgress(data.records.fFinishAmount,data.fSumNeedAmount)}">
														<!-- {{data.taskName == '充值任务' ? '￥'+item.num : item.num+'吨'}} -->
														{{item.fNeedAmount+'吨'}}<span
															v-if="data.fIsOpenTaskName == '台段任务' && index == 0"
															style="color: #ff6600 !important;">(基础台段)</span>
													</div>
													<!-- <div class="normal-text-bottom font-size13 flex-row flex-space-center"
														v-if="data.fIsOpenTask == 0"
														:class="{'text-ff6600': countProgress(item.fSortNO,data.details.length) <= countProgress(data.fSortNO,data.details.length) }">
														{{item.fPoint}}积分/吨
													</div> -->
													<div class="normal-text-bottom font-size13 flex-row flex-space-center"
														
														:class="{'text-ff6600': countProgress(item.fSortNO,data.details.length) <= countProgress(data.fSortNO,data.details.length) }">
														{{ (data.fIsOpenTaskName == '开口任务' || data.fIsOpenTaskName == '台段任务') ?  item.fPoint + '积分/吨' : item.fPoint + '积分' }}
													</div>
												</div>
												<div v-if="data.fIsOpenTaskName == '开口任务'"
													style="margin-top: 35px;color: #ff6600 !important"> 奖励积分=实际量*积分奖励/吨
													<span>，每个任务只能领取一次奖励</span>
												</div>
												<div v-if="data.fIsOpenTaskName == '闭口任务'"
													style="margin-top: 35px;color: #ff6600 !important"> 完成任务后总额积分奖励
													<span>，每个任务只能领取一次奖励</span>
												</div>
												<div v-if="data.fIsOpenTaskName == '台段任务'"
													style="margin-top: 35px;color: #ff6600 !important">
													奖励积分=(实际量-基础值）*区间奖励
													<span>，每个任务只能领取一次奖励</span>
												</div>
											</div>
											<el-divider v-if="dataIndex != taskMy.length - 1"></el-divider>
										</div>
									</div>
								</div>
								<!--充值任务页面-->
								<div v-for="(data,dataIndex) in fGatherTaskMy" :key="'6'+dataIndex"
									class="task-card-item flex-row-space-between">
									<!-- 左侧 -->
									<div class="">
										<div class="icon-box radius5 flex-row-center-center icon-bg-blue">
											<div class="iconfont icon-fahuo font-color-fff font-size24"></div>
										</div>
									</div>
									<!-- 右侧 -->
									<div class="task-card-item-content flex-colum-between">
										<!-- 上半部分 -->
										<div class="flex-row-space-between">
											<!-- 上半部分左侧 -->
											<div class="flex-row" style="align-items: center;">
												<div>
													<!-- 标题 -->
													<div class="font-size15 font-weight700">
														{{data.fTitle}}
													</div>
													<!-- 状态 -->
													<div class="flex-row font-size12 margin-tb-4 flex-row-align-center">
														<div
															class="task-card-item-tag radius2 theme-border-color font-color-fff theme-bg-color margin-r-8">
															{{data.fActivityName}}
														</div>
													</div>
												</div>
												<div v-if="data.fRecord.fStatus == 0 || data.fRecord.fStatus ==1"
													class="margin-l-10 font-size12 font-color-FF0000 border-FF0000 padding-lr-5 radius2"
													style="width:fit-content;height: fit-content;">
													{{data.fRecord.fStatus == 1?"已完成":"未完成"}}
												</div>
											</div>
											<!-- 上半部分右侧 -->
											<div v-if='false' class="font-size14 flex-column-start-end">
												<!-- 功能那个按钮 -->
												<div class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-blue-white pointer"
													@click="makeRechargeOrder(data.fGatherTaskID,data.fGatheringMoney)">
													做任务
												</div>
											</div>
										</div>
										<!-- 下半部分 -->
										<div class="font-size13 font-color-999"
											:class="{'margin-b-15':dataIndex == fGatherTaskMy.length - 1}">
											<!-- 有效日期 -->
											<div class="flex-row-space-between-center margin-tb-4">
												<div>
													<span class="iconfont icon-shijian1 font-size15 margin-r-8"></span>
													有效时间：{{ data.fBeginDate }} 至 {{ data.fEndDate }}
												</div>
												<div v-if="data.fCanDelete == 1"
													class="pointer margin-r-10 delete-hover font-size15"
													@click="deleteTaskgather(data)"><i class="el-icon-delete"></i></div>

											</div>
											<!-- 任务状态 0~n+1 -->
											<!-- 如果有商品名称就是指定商品发货任务 -->
											<div v-if="data.fGoodsNames != ''"
												v-for="(item,index) in data.fGoodsNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 如果有仓库就是仓库发货任务 -->
											<div v-if="data.fDepotNames != ''"
												v-for="(item,index) in data.fDepotNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 提货方式 -->
											<div class="delivery-method" v-if="data.fDeliverys">
												<img class="delivery-img" src="@/assets/提货.png" alt="">
												<span>{{data.fDeliverys}}</span>
											</div>
											<!-- 进度条 （很复杂 最后做） -->
											<div class="task-card-item-progress margin-tb-10">
												<div v-if='data.fRecord.fStatus == 0'
													class="normal-line radius20 bg-F2F2F2"></div>
												<div v-else class="normal-line radius20 bg-F2F2F2 icon-bg-blue"></div>
												<div :class='data.fRecord.fStatus == 0 ? `normal-line-dot bg-F2F2F2 radius20 flex-row-center-center`: `icon-bg-FF9900  normal-line-dot bg-F2F2F2 radius20 flex-row-center-center`'
													style="margin-left: 96.5%;top:25px">
													<div
														class="iconfont icon-wujiaoxingxingxingshoucangmoren font-color-fff">
													</div>
													<div
														class="normal-text-top font-size13 flex-row flex-space-center font-color-999">

														{{data.fGatheringMoney+'元'}}
													</div>
													<div
														class="normal-text-bottom font-size13 flex-row flex-space-center ">
														{{data.fPointAmount}}积分
													</div>
												</div>
												<div style="margin-top: 35px;color: #ff6600 !important">
													完成充值任务即可获取奖励，充值金额未实现出厂前为冻结状态，冻结金额=充值金额-出厂金额</div>
											</div>
											<el-divider v-if="dataIndex != fGatherTaskMy.length - 1"></el-divider>
										</div>
									</div>
								</div>
							</div>
							<div v-if="activeName == 'fOrderTask'">
								<!--订单任务页面-->
								<div v-for="(data,dataIndex) in fOrderTaskMy" :key="'5'+dataIndex"
									class="task-card-item flex-row-space-between">
									<!-- 左侧 -->
									<div class="">
										<div class="icon-box radius5 flex-row-center-center icon-bg-blue">
											<div class="iconfont icon-fahuo font-color-fff font-size24"></div>
										</div>
									</div>
									<!-- 右侧 -->
									<div class="task-card-item-content flex-colum-between">
										<!-- 上半部分 -->
										<div class="flex-row-space-between">
											<!-- 上半部分左侧 -->
											<div class="flex-row" style="align-items: center;">
												<div>
													<!-- 标题 -->
													<div class="font-size15 font-weight700">
														{{data.fTitle}}
													</div>
													<!-- 状态 -->
													<div class="flex-row font-size12 margin-tb-4 flex-row-align-center">
														<div
															class="task-card-item-tag radius2 theme-border-color font-color-fff theme-bg-color margin-r-8">
															{{data.fTaskTypeName}}
														</div>
													</div>
												</div>
												<div v-if="data.fRecord.fStatus == 0 || data.fRecord.fStatus ==1"
													class="margin-l-10 font-size12 font-color-FF0000 border-FF0000 padding-lr-5 radius2"
													style="width:fit-content;height: fit-content;">
													{{data.fRecord.fStatus == 1?"已完成":"未完成"}}
												</div>
											</div>
											<!-- 上半部分右侧 -->
											<div v-if='false' class="font-size14 flex-column-start-end">
												<!-- 功能那个按钮 -->
												<div class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-blue-white pointer"
													@click="makeOrderTask(data.fOrderTaskID,data.fGoodsID,data.fPointAmount,data.fRecord.fOrderID)">
													做任务
												</div>
											</div>
										</div>
										<!-- 下半部分 -->
										<div class="font-size13 font-color-999"
											:class="{'margin-b-15':dataIndex == fOrderTaskMy.length - 1}">
											<!-- 有效日期 -->
											<div class="flex-row-space-between-center margin-tb-4">
												<div>
													<span class="iconfont icon-shijian1 font-size15 margin-r-8"></span>
													有效日期：{{data.fBeginDate}} 至 {{data.fEndDate}}
												</div>
												<div v-if="data.fCanDelete == 1"
													class="pointer margin-r-10 delete-hover font-size15"
													@click="deleteTaskorder(data)"><i class="el-icon-delete"></i></div>
											</div>
											<!-- 任务状态 0~n+1 -->
											<!-- 如果有商品名称就是指定商品发货任务 -->
											<div v-if="data.fGoodsNames != ''"
												v-for="(item,index) in data.fGoodsNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 如果有仓库就是仓库发货任务 -->
											<div v-if="data.fDepotNames != ''"
												v-for="(item,index) in data.fDepotNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 提货方式 -->
											<div class="delivery-method" v-if="data.fDeliverys">
												<img class="delivery-img" src="@/assets/提货.png" alt="">
												<span>{{data.fDeliverys}}</span>
											</div>
											<!-- 进度条 （很复杂 最后做） -->
											<div class="task-card-item-progress margin-tb-10">
												<div v-if='data.fRecord.fStatus == 0'
													class="normal-line radius20 bg-F2F2F2"></div>
												<div v-else class="normal-line radius20 bg-F2F2F2 icon-bg-blue"></div>
												<div style="margin-left: 96.5%;top:25px"
													:class='data.fRecord.fStatus == 0 ? `normal-line-dot bg-F2F2F2 radius20 flex-row-center-center`: `icon-bg-FF9900  normal-line-dot bg-F2F2F2 radius20 flex-row-center-center`'>
													<div
														class="iconfont icon-wujiaoxingxingxingshoucangmoren font-color-fff">
													</div>
													<div
														class="normal-text-top font-size13 flex-row flex-space-center font-color-999">
							
														{{data.fOrderAmount+'吨'}}
													</div>
													<div
														class="normal-text-bottom font-size13 flex-row flex-space-center">
														{{data.fPointAmount}}积分
													</div>
												</div>
												<div v-if="data.fTaskTypeName == '常规订单任务'"
													style="margin-top: 35px;color: #ff6600 !important">
													领取任务即获取奖励，未支付订单视同未完成任务，订单退款不退奖励积分</div>
												<div v-if="data.fTaskTypeName == '首次发货订单任务'"
													style="margin-top: 35px;color: #ff6600 !important">
													首次在店铺下单即可获取奖励，未支付订单视同未完成任务，订单退款不退奖励积分</div>
											</div>
										</div>
										<el-divider v-if="dataIndex != fOrderTaskMy.length - 1"></el-divider>
									</div>
								</div>
							</div>
							<div v-if="activeName == 'fDeliverTask'">
								<div v-for="(data,dataIndex) in taskMy" :key="'1'+dataIndex"
									class="task-card-item padding-lr-13 flex-row-space-between">
									<!-- 左侧 -->
									<div class="">
										<div class="icon-box radius5 flex-row-center-center icon-bg-blue">
											<!--  :class="{'icon-bg-blue':data.taskName == '发货任务','icon-bg-orange':data.taskName == '充值任务','icon-bg-red':data.taskName == '下单任务'}" -->
											<div class="iconfont icon-fahuo font-color-fff font-size24"></div>
											<!--  v-if="data.taskName == '发货任务'" -->
											<!-- <div v-if="data.taskName == '充值任务'" class="iconfont icon-chongzhi font-color-fff font-size24"></div>
												<div v-if="data.taskName == '下单任务'" class="iconfont icon-xiadan1 font-color-fff font-size24"></div> -->
										</div>
									</div>
									<!-- 右侧 -->
									<div class="task-card-item-content flex-colum-between">
										<!-- 上半部分 -->
										<div class="flex-row-space-between">
											<!-- 上半部分左侧 -->
											<div class="flex-row">
												<div>
													<!-- 标题 -->
													<div class="font-size15 font-weight700">
														{{data.fTitle}}
													</div>
													<!-- 积分 -->
													<!-- 		<div class="font-size13 font-color-999 flex-row-align-center margin-tb-4">
															<span class="shoucang-one iconfont icon-shoucang font-size15 font-color-FF9900 margin-r-6"></span>
															最高奖励{{data.fSumPointAmount}} 积分
														</div> -->
													<!-- 状态 -->
													<div class="flex-row font-size12 margin-tb-4 flex-row-align-center">
														<div
															class="task-card-item-tag radius2 theme-border-color font-color-fff theme-bg-color margin-r-8">
															{{data.fTaskTypeName}}
														</div>
														<!-- <div v-if="data.type2 != ''" class="task-card-item-tag radius2 theme-border-color theme-text-color">
																{{data.type2}}
															</div> -->
														<!-- <div v-if="data.share" class="text-ff6600">
																<span class="iconfont icon-arrow- margin-l-10 margin-r-2"></span>分享得积分
															</div> -->
													</div>
												</div>
												<div v-if="data.records.fStatus == 1 || data.records.fStatus ==2"
													class="margin-l-10 font-size12 font-color-FF0000 border-FF0000 padding-lr-5 radius2"
													style="width:fit-content;height: fit-content;">
													{{data.records.fStatus == 1?"已完成":"未完成"}}
												</div>
											</div>
											<!-- 上半部分右侧 -->
											<div class="font-size13 flex-column-start-end">
												<!-- 功能那个按钮 -->
												<!-- fStatus：0初始1已完成2未完成 -->
												<!--按照最新要求，去做任务，暂不显示(2022/5/19)-->
												<div v-if="false" style="cursor: pointer;"
													class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-h-30"
													@click="goShoplist"
													:class="{'task-card-item-btn-blue-white':data.records.fStatus == 0,'task-card-item-btn-orange-white':data.records.fStatus == 1,'task-card-item-btn-white-blue task-card-item-btn-h-28':data.records.fStatus == 2}">
													去做任务
												</div>
												<!-- <div v-if="data.records.fStatus == 1"  class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-h-30"
													 :class="{'task-card-item-btn-blue-white':data.records.fStatus == 0,'task-card-item-btn-orange-white':data.records.fStatus == 1,'task-card-item-btn-white-blue task-card-item-btn-h-28':data.records.fStatus == 2}">
														已完成
													</div> -->
												<!-- data.records.fEnableReceivePointStatus==1 -->
												<div v-if="data.records.fEnableReceivePointStatus==1"
													style="cursor: pointer;user-select: none;"
													class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-h-30"
													@click="getTask(data.records)">
													领取奖励
												</div>
												<!-- <div v-if="data.records.fStatus == 2" class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-h-30"
													 :class="{'task-card-item-btn-blue-white':data.records.fStatus == 0,'task-card-item-btn-orange-white':data.records.fStatus == 1,'task-card-item-btn-white-blue task-card-item-btn-h-28':data.records.fStatus == 2}">
														未完成
													</div> -->
												<!-- 完成状态 -->
												<div
													class="task-card-item-progress-text margin-t-15 font-size14 font-color-999">
													已完成<span
														class="theme-text-color">{{data.records.fFinishAmount}}/{{data.records.fNeedAmount}}</span>
												</div>
											</div>
										</div>
										<!-- 下半部分 -->
										<div class="font-size13 font-color-999">
											<!-- 有效日期 -->
											<div class="flex-row-space-between-center margin-tb-4">
												<div>
													<span class="iconfont icon-shijian1 font-size15 margin-r-8"></span>
													有效日期：{{data.fBeginDate}} 至 {{data.fEndDate}}
												</div>
												<div v-if="data.fCanDelete == 1"
													class="pointer margin-r-10 delete-hover font-size15"
													@click="deleteTask(data)"><i class="el-icon-delete"></i></div>
											</div>
											<!-- 任务状态 0~n+1 -->
											<!-- 如果有商品名称就是指定商品发货任务 -->
											<div v-if="data.fGoodsNames != ''"
												v-for="(item,index) in data.fGoodsNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 如果有仓库就是仓库发货任务 -->
											<div v-if="data.fDepotNames != ''"
												v-for="(item,index) in data.fDepotNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 提货方式 -->
											<div class="delivery-method" v-if="data.fDeliverys">
												<img class="delivery-img" src="@/assets/提货.png" alt="">
												<span>{{data.fDeliverys}}</span>
											</div>
											<!-- 进度条 （很复杂 最后做） -->
											<div class="task-card-item-progress margin-tb-10">
												<div class="normal-line radius20 bg-F2F2F2"></div>
												<div class="active-line radius20 theme-bg-color"
													:style="{width: countProgress(data.records.fFinishAmount,data.fSumNeedAmount)+'px'}">
													<!-- 为避免已完成进度和进度点碰撞后重叠 废弃已完成进度提示 -->
													<!-- <span v-if="data.num != 0" class="active-text font-size13 flex-row theme-text-color flex-space-center">
															{{data.type == '充值任务' ? '￥'+data.num : data.num+'吨'}}
														</span> -->
												</div>
												<!-- bg-fff font-color-FF9900 -->
                        <!-- 修改这里 -->
												<div v-for="(item,index) in data.details" :key="'3'+index"
													class="normal-line-dot bg-F2F2F2 radius20 flex-row-center-center"
													:class="{'bg-FF9900': countProgress(item.fNeedAmount,data.fSumNeedAmount) <= countProgress(data.records.fFinishAmount,data.fSumNeedAmount) }"
													:style="{marginLeft:  schedulelength(item.fNeedAmount,data)+'px',top:25-index*3.5+'px'}">
                          <!-- index+1 == data.details.length ? countProgress(item.fNeedAmount,data.fSumNeedAmount) - 20 + 'px' : -->
													<div
														class="iconfont icon-wujiaoxingxingxingshoucangmoren font-color-fff">
													</div>
                          
													<div class="normal-text-top font-size13 flex-row flex-space-center font-color-999"
														:class="{'text-333': countProgress(item.fNeedAmount,data.fSumNeedAmount) <= countProgress(data.records.fFinishAmount,data.fSumNeedAmount)}">
														<!-- {{data.taskName == '充值任务' ? '￥'+item.num : item.num+'吨'}} -->
														{{item.fNeedAmount+'吨'}} <span
															v-if="data.fIsOpenTaskName == '台段任务' && index == 0"
															style="color: #ff6600 !important;">(基础台段)</span>
													</div>
													<!-- <div class="normal-text-bottom font-size13 flex-row flex-space-center"
														v-if="data.fIsOpenTask == 0"
														:class="{'text-ff6600': countProgress(item.fSortNO,data.details.length) <= countProgress(data.fSortNO,data.details.length) }">
														{{item.fPoint}}积分/吨
													</div> -->
                          
													<div class="normal-text-bottom font-size13 flex-row flex-space-center"
														
														:class="{'text-ff6600': countProgress(item.fSortNO,data.details.length) <= countProgress(data.fSortNO,data.details.length) }">
														{{ (data.fIsOpenTaskName == '开口任务' || data.fIsOpenTaskName == '台段任务') ?  item.fPoint + '积分/吨' : item.fPoint + '积分' }}
													</div>
												</div>
												<div v-if="data.fIsOpenTaskName == '开口任务'"
													style="margin-top: 35px;color: #ff6600 !important"> 奖励积分=实际量*积分奖励/吨
													<span>，每个任务只能领取一次奖励</span>
												</div>
												<div v-if="data.fIsOpenTaskName == '闭口任务'"
													style="margin-top: 35px;color: #ff6600 !important"> 完成任务后总额积分奖励
													<span>，每个任务只能领取一次奖励</span>
												</div>
												<div v-if="data.fIsOpenTaskName == '台段任务'"
													style="margin-top: 35px;color: #ff6600 !important">
													奖励积分=(实际量-基础值）*区间奖励
													<span>，每个任务只能领取一次奖励</span>
												</div>
											</div>
											<el-divider v-if="dataIndex != taskMy.length - 1"></el-divider>
										</div>
									</div>
								</div>
							</div>
							<div v-if="activeName == 'fGatherTask'">
								<div v-for="(data,dataIndex) in fGatherTaskMy" :key="'6'+dataIndex"
									class="task-card-item flex-row-space-between">
									<!-- 左侧 -->
									<div class="">
										<div class="icon-box radius5 flex-row-center-center icon-bg-blue">
											<div class="iconfont icon-fahuo font-color-fff font-size24"></div>
										</div>
									</div>
									<!-- 右侧 -->
									<div class="task-card-item-content flex-colum-between">
										<!-- 上半部分 -->
										<div class="flex-row-space-between">
											<!-- 上半部分左侧 -->
											<div class="flex-row" style="align-items: center;">
												<div>
													<!-- 标题 -->
													<div class="font-size15 font-weight700">
														{{data.fTitle}}
													</div>
													<!-- 状态 -->
													<div class="flex-row font-size12 margin-tb-4 flex-row-align-center">
														<div
															class="task-card-item-tag radius2 theme-border-color font-color-fff theme-bg-color margin-r-8">
															{{data.fActivityName}}
														</div>
													</div>
												</div>
												<div v-if="data.fRecord.fStatus == 0 || data.fRecord.fStatus ==1"
													class="margin-l-10 font-size12 font-color-FF0000 border-FF0000 padding-lr-5 radius2"
													style="width:fit-content;height: fit-content;">
													{{data.fRecord.fStatus == 1?"已完成":"未完成"}}
												</div>
											</div>
											<!-- 上半部分右侧 -->
											<div v-if='false' class="font-size14 flex-column-start-end">
												<!-- 功能那个按钮 -->
												<div class="task-card-item-btn padding-lr-8 radius30 bg-FF9900 font-color-fff margin-b-2 task-card-item-btn-blue-white pointer"
													@click="makeRechargeOrder(data.fGatherTaskID,data.fGatheringMoney)">
													做任务
												</div>
											</div>
										</div>
										<!-- 下半部分 -->
										<div class="font-size13 font-color-999"
											:class="{'margin-b-15':dataIndex == fGatherTaskMy.length - 1}">
											<!-- 有效日期 -->
											<div class="flex-row-space-between-center margin-tb-4">
												<div>
													<span class="iconfont icon-shijian1 font-size15 margin-r-8"></span>
													有效时间：{{ data.fBeginDate }} 至 {{ data.fEndDate }}
												</div>
												<div v-if="data.fCanDelete == 1"
													class="pointer margin-r-10 delete-hover font-size15"
													@click="deleteTaskgather(data)"><i class="el-icon-delete"></i></div>

											</div>
											<!-- 任务状态 0~n+1 -->
											<!-- 如果有商品名称就是指定商品发货任务 -->
											<div v-if="data.fGoodsNames != ''"
												v-for="(item,index) in data.fGoodsNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 如果有仓库就是仓库发货任务 -->
											<div v-if="data.fDepotNames != ''"
												v-for="(item,index) in data.fDepotNamesList" :key="'2'+index"
												class="flex-row-align-center margin-tb-4">
												<span class="iconrenwu margin-r-8"></span>{{item}}
											</div>
											<!-- 提货方式 -->
											<div class="delivery-method" v-if="data.fDeliverys">
												<img class="delivery-img" src="@/assets/提货.png" alt="">
												<span>{{data.fDeliverys}}</span>
											</div>
											<!-- 进度条 （很复杂 最后做） -->
											<div class="task-card-item-progress margin-tb-10">
												<div v-if='data.fRecord.fStatus == 0'
													class="normal-line radius20 bg-F2F2F2"></div>
												<div v-else class="normal-line radius20 bg-F2F2F2 icon-bg-blue"></div>
												<div :class='data.fRecord.fStatus == 0 ? `normal-line-dot bg-F2F2F2 radius20 flex-row-center-center`: `icon-bg-FF9900  normal-line-dot bg-F2F2F2 radius20 flex-row-center-center`'
													style="margin-left: 96.5%;top:25px">
													<div
														class="iconfont icon-wujiaoxingxingxingshoucangmoren font-color-fff">
													</div>
													<div
														class="normal-text-top font-size13 flex-row flex-space-center font-color-999">

														{{data.fGatheringMoney+'元'}}
													</div>
													<div
														class="normal-text-bottom font-size13 flex-row flex-space-center ">
														{{data.fPointAmount}}积分
													</div>
												</div>
												<div style="margin-top: 35px;color: #ff6600 !important">
													完成充值任务即可获取奖励，充值金额未实现出厂前为冻结状态，冻结金额=充值金额-出厂金额</div>
											</div>
											<el-divider v-if="dataIndex != fGatherTaskMy.length - 1"></el-divider>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="svg-no-content" v-if="isShow"></div> -->
						<div class="flex-row-center-center" v-if="isShow">
							<svg-icon icon-class="NoDataAvailable" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState,
		mapGetters
	} from 'vuex';
	export default {
		// props: {
		// 	shoptype: {
		// 		type: String,
		// 		default: '1'
		// 	},
		// 	shopId: {
		// 		type: String,
		// 		default: ''
		// 	}
		// },
		data() {
			return {
				loading: false,
				fSaleAreaIDs: '',
				// fShopUnitID: -1,
				sumCustomerPoint: '', //总积分
				completed: '', //已完成任务(需要接口)
				toBeCompleted: '', //待完成任务
				taskBg: require("@/assets/imgs/Home/Task/taskBg.png"),
				// tab标签激活状态
				tabActive: 1,
				// 任务集市 
				// state  1：领任务  2：去做任务  3：领取奖励  4：删除  5：已完成
				taskMarket: [],
				fOrderTask: [], //订单任务
				fGatherTask: [], //充值任务
				// 我的任务
				// state  1：领任务  2：去做任务  3：领取奖励  4：删除  5：已完成
				taskMy: [],
				fOrderTaskMy: [], //订单任务
				fGatherTaskMy: [], //充值任务
				shoptype: "",
				shopId: null,
				liShow: true,
				activeName: 'total',
				newArr: [],
				label: '全部任务',
				// leiB: '待完成',
				searchDate: ['', ''],
				saleAreaMy: [],
				asideMenu: [],
				activeClass: undefined,
				fSaleAreaIDsShow: '',
				taskMarketA: [],
				fOrderTaskA: [],
				fGatherTaskA: [],
				saleAreaA: [],
				taskMarketAreaA: [],
				fOrderTaskAreaA: [],
				fGatherTaskAreaA: [],
				filterText: '',
				treeExpandedKeys: [], // 默认展开项
				firstGetData: false,
				addVisible: '',
				loadingTree: false,
				treeData: [],
				defaultProps: {
					children: 'children',
					label: 'fSaleArea',					
				},
				expandClick: true,
				expandedKeys: [],
				changeTreeData: {}, //树节点数据
				currentNodeKey:""
			};
		},
		computed: {
			// ...mapState({
			// 	current: state => state.user.currentMenu
			// }),
			...mapGetters(['getThemeName', 'getThemeColor', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			isShow() {
				if (this.tabActive == 1) {
					if (this.taskMarket.length < 1 && this.fOrderTask.length < 1 && this.fGatherTask.length < 1) {
						return true;
					} else {
						return false;
					}
				} else {
					if (this.taskMy.length < 1 && this.fOrderTaskMy.length < 1 && this.fGatherTaskMy.length < 1) {
						return true;
					} else {
						return false;
					}
				}
			},
			noChildren() {
				return this.asideMenu.filter(item => !item.children)
			},
			hasChildren() {
				return this.asideMenu.filter(item => item.children)
			},
		},
		mounted() {
			this.firstGetData = true
			const start = this.getDate(new Date().setDate(new Date().getDate() - 30))
			const end = new Date();
			// this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 90))
			this.searchDate[0] = start
			this.searchDate[1] = end
			// this.queryListData();
			// this.fShopUnitID = this.$route.query.id;
			if (this.$route.query.shoptype) {
				this.shoptype = this.$route.query.shoptype;
				this.shopId = this.$route.query.shopId;
			}
			// console.log(this.$route.query.id,"this.$route.query.id",this.fShopUnitID)
			this.getTasksStatistic();
			this.getMyPoint();
			// this.getListByNoGet();
			// this.getListByCurrentTask();
			this.getListAllNoGet(); //获取所有任务
			// this.getAllCurrent(); //获取当前我的任务
			this.getAsea()
		},
		watch: {
      taskMarket(newValue,oldValue){
        console.log(newValue,oldValue)
      },
			fSaleAreaIDs(newValue, oldValue) {
				this.chuL(newValue, this.label)
				newValue = ''
			}
		},
		methods: {
      //计算长度
      schedulelength(val,data){
        // console.log(data)
        let Arr = []
        data.details.forEach(item=>{
          Arr.push(item.fNeedAmount)
        })
             Arr = this.newsort(Arr)
        var schedule = (val/Arr[Arr.length-1])*630
        console.log(schedule)
        if(schedule<630){
          return schedule
        }else{
          return 610 
        }
      },
      //数组大小排序
      newsort(Arr){
        let min;
        for(var i=0; i<Arr.length; i++){
                for(var j=i; j<Arr.length;j++){
                    if(Arr[i]>Arr[j]){
                      min=Arr[j];
                      Arr[j]=Arr[i];
                      Arr[i]=min;
                    }
                }
            }
            return Arr
      },
			//树数据处理
			toTreeData(data) {
				data.forEach(function(item) {
					item.children = [];
				});
				var map = {};
				data.forEach(function(item) {
					map[item.fSaleAreaID] = item;
				});
				var val = [];
				data.forEach(function(item) {
					// 以当前遍历项，的fParentID,去map对象中找到索引的区域id
					var parent = map[item.fParentID];
					// 如果找到索引，那么说明此项不在顶级当中,那么需要把此项添加到，他对应的父级中
					if (parent) {
						(parent.children || (parent.children = [])).push(item);
					} else {
						//如果没有在map中找到对应的索引ID,那么直接把 当前的item添加到 val结果集中，作为顶级
						val.push(item);
					}
				});
				return val;
			},
			//树模糊查询
			filterNode(value, data, node) {
			  if (!value) return true
			  let parentNode = node.parent
			  let labels = [node.label]
			  let level = 1
			  while (level < node.level) {
			    labels = [...labels, parentNode.label]
			    parentNode = parentNode.parent
			    level++
			  }
			  return labels.some(label => label.indexOf(value) !== -1)
			},
			// 节点被展开时触发的事件   共三个参数，依次为：传递给 data 属性的数组中该节点所对应的对象、节点对应的 Node、节点组件本身
			nodeExpand(item) {
				if (this.treeExpandedKeys.length == 0) {
					this.treeExpandedKeys.push(item.fSaleAreaID);
				} else {
					for (let idd of this.treeExpandedKeys) {
						if (idd != item.fSaleAreaID) {
							this.treeExpandedKeys.push(item.fSaleAreaID);
							break;
						}
					}
				}
			},
			// 节点被关闭时触发的事件    共三个参数，依次为：传递给 data 属性的数组中该节点所对应的对象、节点对应的 Node、节点组件本身
			nodeCollapse(item) {
				this.treeExpandedKeys.splice(
					this.treeExpandedKeys.indexOf(this.treeExpandedKeys.find(
						function(element) {
							return element == item.fSaleAreaID;
						})), 1 //splice方法里的1不要删除
				);
			},
			// 加载默认打开项
			RecastData(val) {
			  for(let item of val){
			    if(item.fParentID == -1 || item.fParentID == 0){
			      this.treeExpandedKeys.push(item.fSaleAreaID)
			      this.$refs.tree.store.defaultExpandedKeys.push(item.fSaleAreaID)
			    }
			  }
			},
			//树点击事件
			handleNodeClick(node, data) {
				this.activeClass = node.fSaleAreaID == -1?undefined:node.fSaleAreaID
				this.fSaleAreaIDs = node.fSaleAreaID == -1?undefined:node.fSaleAreaID
			},
			queryListData() {
				this.getAllCurrent()
			},
			// finish(value) {
			// 	switch (value) {
			// 		case '已完成':
			// 			this.leiB = value
			// 			break;
			// 		case '待完成':
			// 			this.leiB = value
			// 			break;
			// 	}
			// },
			clickMenu(item, index) {
				this.activeClass = item.fSaleAreaIDs;
				this.fSaleAreaIDs = item.fSaleAreaIDs
				// this.taskMarket = [];
				// this.fOrderTask = [];
				// this.fGatherTask = []
			},
			//点击订单 去做任务
			makeOrderTask(val1, val2, val3, val4) {
				//证明是从任务集市进去的
				if (this.tabActive == 1) {
					this.loading = true
					let params = {}
					params.fOrderTaskID = val1
					params.fGoodsID = val2
					params.fAppTypeID = this.fAppTypeID
					this.ApiRequestPostNOMess('api/mall/ebsale/order/create-order-task-new',
						params).then(res => {
						console.log(res)
						this.loading = false
						if (res.obj == null) {
							this.$message(res.message);
							this.getListAllNoGet()
							this.$forceUpdate()
							return
						}
						//跳转到任务订单页面
						this.$router.push({
							path: 'shoppingCart/createTaskOrder',
							query: {
								integralNumber: val3,
								id: res.obj.fOrderID,
								fOrderTaskID: val1
							}
						})
					}, error => {
						this.getListAllNoGet()
						this.$forceUpdate()
						this.loading = false
					});
				}
				//证明是从我的任务进去的
				if (this.tabActive == 2) {
					this.$router.push({
						path: 'shoppingCart/createTaskOrder',
						query: {
							integralNumber: val3,
							id: val4
						}
					})
				}

			},
			handleClick(tab, event) {
				switch (tab.label) {
					case '全部任务':
						if (this.tabActive == '1') {
							this.chuL(this.fSaleAreaIDs, '全部任务');
						} else {
							this.getAllCurrent('全部任务');
						}
						this.label = '全部任务'
						break;
					case '充值任务':
						if (this.tabActive == '1') {
							this.chuL(this.fSaleAreaIDs, '充值任务');
						} else {
							this.getAllCurrent('充值任务');
						}
						this.label = '充值任务'
						break;
					case '订单任务':
						if (this.tabActive == '1') {
							this.chuL(this.fSaleAreaIDs, '订单任务');
						} else {
							this.getAllCurrent('订单任务');
						}
						this.label = '订单任务'
						break;
					case '提货任务':
						if (this.tabActive == '1') {
							this.chuL(this.fSaleAreaIDs, '提货任务');
						} else {
							this.getAllCurrent('提货任务');
						}
						this.label = '提货任务'
						break;
				}
			},
			//创建充值订单
			makeRechargeOrder(val1, val2) {
				//1.先判断这个人身份；企业的话直接跳转；个人的话判断有无银行卡
				if (this.getUserInfo.fCommerceTypeID == 1) {
					//判断这个人是否有银行卡
        //   this.ApiRequestPostNOMess('/api/mall/ebactivity/gather-task/get-number-by-taskid',{
				// 	id:val1
				// }).then(result => {
          // console.log(res)
					this.haveCard(val1, val2)
        // })
				} else {
          //判断是否进行下一步任务 
				// this.ApiRequestPostNOMess('/api/mall/ebactivity/gather-task/get-number-by-taskid',{
				// 	id:val1
				// }).then(result => {
          // console.log(res)
          this.$router.push({
						path: 'businessme/createRechargeOrder',
						query: {
							id: val1
						}
					})
        // },
        // error => {
        //   //失败封装的接口会自己报403的问题
        //   console.log(error)
        // }
        // )
				 }
			},
			//判断这个人有无银行卡
			haveCard(val1, val2) {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/bank-account/get-cpcn-physical-account-list').then(res => {
					if (res.obj.length != 0) {
						this.$router.push({
							path: 'businessme/createRechargeOrder',
							query: {
								id: val1
							}
						})
					} else {
						this.$message('亲，您还未绑定充值卡哦~');
					}
				}, error => {});
			},
			// 任务奖励领取提示
			getTask(e) {
				this.ApiRequestPost('api/mall/ebactivity/deliver-task-record/get-task-isend', {
					beginTime: e.fModifyTime,
					endTime: e.fEndDate,
					id: e.fBillID
				}).then(res => {
					//fIsEnd为0时表示所有阶段任务已完成，不需要提示
					if (res.obj.fIsEnd == 0) {
						this.receiveReward(e)
					} else {

						this.$confirm(res.obj.fIsEndMessage, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.receiveReward(e)
							this.$message({
								type: 'success',
								message: '领取成功!'
							});
						}).catch(() => {});
					}
				})
			},
			goShoplist() {
				// 导航至/商品列表
				this.$router.push("/Goods");
			},

			//tab点击事件
			tabActiveChange(active) {
				this.tabActive = active;
				if (this.tabActive == 1) {
					this.getAsea()
					this.getListAllNoGet()
					// console.log(this.fSaleAreaIDs,'切换')
				} else if (this.tabActive == 2) {
					this.getAllCurrent()
				}
			},
			//获取进度条的宽度
			countProgress(num, total,val) {
				// 630 为当前进度条元素的宽度
				if (num > total) {
					num = total
				}
				return num / total * 630
			},
			//获取已完成待完成
			getTasksStatistic() {
				let parms = {
					maxResultCount: 10,
					skipCount: 0
				};
				if (this.shoptype == '2') {
					parms.fShopUnitID = this.shopId;
				}
				this.ApiRequestPostNOMess('api/mall/ebactivity/deliver-task/get-tasks-statistic',
					parms).then(res => {
					this.completed = res.obj.completNumber;
					this.toBeCompleted = res.obj.unCompletNumber;
				}, error => {

				});
			},
			//获取总积分
			getMyPoint() {
				this.ApiRequestPostNOMess('api/mall/ebbalance/customer-point/get-my-point', {
					maxResultCount: 10,
					skipCount: 0
				}, ).then(res => {
					this.sumCustomerPoint = res.obj.sumCustomerPoint;
				}, error => {

				});
			},
			//获取未领取过的任务列表
			getListByNoGet() {
				let parms = {
					maxResultCount: 100,
					skipCount: 0
				};
				if (this.shoptype == '2') {
					parms.fShopUnitID = this.shopId;
				}
				this.ApiRequestPostNOMess('/api/mall/ebactivity/deliver-task/get-list-by-no-get',
					parms
				).then(
					result => {
						// console.log(result,"获取未领取过的任务列表");
						if (result.obj.items.length > 0) {
							result.obj.items.forEach((item) => {
								item.fBeginDate = this.getDate(item.fBeginDate);
								item.fEndDate = this.getDate(item.fEndDate);
								item.fGradeIDsList = item.fGradeIDs.split(',');
								if (item.fGoodsNames != '') {
									item.fGoodsNamesList = item.fGoodsNames.split(',');
								}
								if (item.fDepotNames != '') {
									item.fDepotNamesList = item.fDepotNames.split(',');
								}
							});
						}
						this.taskMarket = result.obj.items;
					},
					rej => {}
				);
			},

			// 左边区域的数据
			getAsea() {
				let parms = {
					maxResultCount: 100,
					skipCount: 0
				};
				if (this.shoptype == '2') {
					parms.fShopUnitId = this.shopId;
				}
				this.ApiRequestPostNOMess('/api/mall/ebactivity/deliver-task/get-list-by-no-get-new-area',
					parms
				).then(
					result => {
						if(result.obj.fDeliverTask.length !=0){
							for(let item of result.obj.fDeliverTask){
								item.fSaleAreaIDs = "," +item.fSaleAreaIDs
							}
						}
						if(result.obj.fOrderTask.length !=0){
							for(let item of result.obj.fOrderTask){
								item.fSaleAreaIDs = "," +item.fSaleAreaIDs
							}
						}
						if(result.obj.fGatherTask.length !=0){
							for(let item of result.obj.fGatherTask){
								item.fSaleAreaIDs = "," +item.fSaleAreaIDs
							}
						}
						this.arr = []
						this.saleArea = result.obj.saleArea
						this.saleArea.forEach(item => {
							this.arr.push({
								fSaleAreas: item.fSaleArea,
								fSaleAreaIDs: item.fSaleAreaID
							})
						})
						this.arr.unshift({
							fSaleAreaIDs: undefined,
							fSaleAreas: "全部",
						});
						this.asideMenu = this.arr
					})
			},
			//新接口（获取所有任务，包括订单任务，仓库任务，充值任务）
			getListAllNoGet() {
				// console.log(fSaleAreaIDs, value, '22222222222222222222222')
				let parms = {
					maxResultCount: 100,
					skipCount: 0
				};
				if (this.shoptype == '2') {
					parms.fShopUnitId = this.shopId;
				}
				// api/mall/ebactivity/deliver-task/get-list-by-no-new-get
				this.ApiRequestPostNOMess('/api/mall/ebactivity/deliver-task/get-list-by-no-get-new-area',
					parms
				).then(
					result => {
						if(result.obj.fDeliverTask.length !=0){
							for(let item of result.obj.fDeliverTask){
								item.fSaleAreaIDs = "," +item.fSaleAreaIDs
							}
						}
						if(result.obj.fOrderTask.length !=0){
							for(let item of result.obj.fOrderTask){
								item.fSaleAreaIDs = "," +item.fSaleAreaIDs
							}
						}
						if(result.obj.fGatherTask.length !=0){
							for(let item of result.obj.fGatherTask){
								item.fSaleAreaIDs = "," +item.fSaleAreaIDs
							}
						}
						this.taskMarket = []
						this.fOrderTask = []
						this.fGatherTask = []
						this.loadingTree = false
						this.treeData = this.toTreeData(result.obj.saleArea)
						if (this.firstGetData) {
							this.RecastData(result.obj.saleArea)
							this.firstGetData = false
						}
						this.$refs.tree.store.defaultExpandedKeys = this.treeExpandedKeys
						//==========
						if (result.obj.fDeliverTask.length > 0) {
							result.obj.fDeliverTask.forEach((item) => {
								item.fBeginDate = this.getDate(item.fBeginDate);
								item.fEndDate = this.getDate(item.fEndDate);
								item.fGradeIDsList = item.fGradeIDs.split(',');
								if (item.fGoodsNames != '') {
									item.fGoodsNamesList = item.fGoodsNames.split(',');
								}
								if (item.fDepotNames != '') {
									item.fDepotNamesList = item.fDepotNames.split(',');
								}
							});
							this.taskMarket = result.obj.fDeliverTask;
              this.taskMarket.forEach(item=>{
                //判断展示特定样式
                for(let i=0;i<item.details.length;i++){
                  for(let j=i+1;j<item.details.length;j++){
                    if(item.details[i].fSortNO == item.details[j].fSortNO){
                      item.details[i].check=true
                      item.details[j].check= true
                    }else{
                      item.details[i].check=false
                      item.details[j].check= false
                    }
                  }
                }

              })
              console.log(this.taskMarket,'数据劫持')
						}
						if (result.obj.fOrderTask.length > 0) {
							result.obj.fOrderTask.forEach((item) => {
								item.fBeginDate = this.getDate(item.fBeginDate);
								item.fEndDate = this.getDate(item.fEndDate);
								item.fGradeIDsList = item.fGradeIDs.split(',');
								if (item.fGoodsFullName != '') {
									item.fGoodsNamesList = item.fGoodsFullName.split(',');
								}
							});
							this.fOrderTask = result.obj.fOrderTask;
						}
						if (result.obj.fGatherTask.length > 0) {
							result.obj.fGatherTask.forEach((item) => {
								item.fBeginDate = this.getTime(item.fBeginDate);
								item.fEndDate = this.getTime(item.fEndDate);
							});
							this.fGatherTask = result.obj.fGatherTask;
						}
						//============
						this.taskMarketA = result.obj.fDeliverTask
						this.fOrderTaskA = result.obj.fOrderTask
						this.fGatherTaskA = result.obj.fGatherTask
						this.saleAreaA = result.obj.saleArea
						this.taskMarketAreaA = result.obj.fDeliverTaskArea
						this.fOrderTaskAreaA = result.obj.fOrderTaskArea
						this.fGatherTaskAreaA = result.obj.fGatherTaskArea
						this.chuL()
					},
					rej => {}
				);
			},
			chuL(fSaleAreaIDs, value) {
				// console.log(fSaleAreaIDs,value,'777777777777777777')
				this.taskMarket = []
				this.fOrderTask = []
				this.fGatherTask = []
				// console.log(this.taskMarketA,'提货')
				// console.log(this.fOrderTaskA,'订单')
				// console.log(this.fGatherTaskA,'充值')
				// console.log(this.saleAreaA,'区域')
				// console.log(this.taskMarketAreaA,'提货区域')
				// console.log(this.fGatherTaskAreaA,'充值区域')
				// console.log(this.fOrderTaskAreaA,'订单区域')
				if (value == '全部任务' || value == undefined) {
					// 表示没选区域
					if (!this.fSaleAreaIDs) {
						// console.log('没选')
						this.taskMarketA.forEach(item => {
							item.fBeginDate = this.getDate(item.fBeginDate);
							item.fEndDate = this.getDate(item.fEndDate);
						})
						this.taskMarketA.forEach(item => {
							this.taskMarket.push(item);
						})
						this.fGatherTaskA.forEach(item => {
							item.fBeginDate = this.getTime(item.fBeginDate);
							item.fEndDate = this.getTime(item.fEndDate);
						})
						this.fGatherTaskA.forEach(item => {
							this.fGatherTask.push(item);
						})
						this.fOrderTaskA.forEach(item => {
							item.fBeginDate = this.getDate(item.fBeginDate);
							item.fEndDate = this.getDate(item.fEndDate);
						})
						this.fOrderTaskA.forEach(item => {
							this.fOrderTask.push(item);
						})
						this.saleAreaA.forEach(item => {
							item.fBeginDate = this.getDate(item.fBeginDate);
							item.fEndDate = this.getDate(item.fEndDate);
						})
						this.arr = []
						this.saleArea = this.saleAreaA
						let needChange = JSON.parse(JSON.stringify(this.saleArea))
						needChange.unshift({
							fSaleAreaID:"-1",
							fSaleArea:"全部",
						})
						this.treeData = this.toTreeData(needChange)
						if(this.treeData[0].fSaleAreaID == '-1'){
							this.currentNodeKey=this.treeData[0].fSaleAreaID
							this.$nextTick(() => {this.$refs.tree.setCurrentKey(this.currentNodeKey)})
						}
						this.saleArea.forEach(item => {
							this.arr.push({
								fSaleAreas: item.fSaleArea,
								fSaleAreaIDs: item.fSaleAreaID
							})
						})
						this.arr.unshift({
							fSaleAreaIDs: undefined,
							fSaleAreas: "全部",
						});
						this.asideMenu = this.arr
					} else {
						this.arr = []
						this.saleArea = this.saleAreaA
						let needChange = JSON.parse(JSON.stringify(this.saleArea))
						needChange.unshift({
							fSaleAreaID:"-1",
							fSaleArea:"全部",
						})
						this.treeData = this.toTreeData(needChange)
						this.saleArea.forEach(item => {
							this.arr.push({
								fSaleAreas: item.fSaleArea,
								fSaleAreaIDs: item.fSaleAreaID
							})
						})
						this.fSaleAreaIDsShow = ''
						this.arr.forEach(item => {
							if (item.fSaleAreaIDs == this.fSaleAreaIDs.toString()) {
								this.fSaleAreaIDsShow = item.fSaleAreaIDs;
							}
						})
						if (this.fSaleAreaIDsShow) {
							this.activeClass = this.fSaleAreaIDsShow
						} else {
							this.activeClass = undefined
						}
						this.arr.unshift({
							fSaleAreaIDs: undefined,
							fSaleAreas: "全部",
						});
						this.asideMenu = this.arr
						if (this.activeClass == undefined) {
							// 表示已选区域
							this.taskMarketA.forEach(item => {
								item.fBeginDate = this.getDate(item.fBeginDate);
								item.fEndDate = this.getDate(item.fEndDate);
							})
							this.taskMarketA.forEach(item => {
								this.taskMarket.push(item);
							})
							this.fGatherTaskA.forEach(item => {
								item.fBeginDate = this.getTime(item.fBeginDate);
								item.fEndDate = this.getTime(item.fEndDate);
							})
							this.fGatherTaskA.forEach(item => {
								this.fGatherTask.push(item);
							})
							this.fOrderTaskA.forEach(item => {
								item.fBeginDate = this.getDate(item.fBeginDate);
								item.fEndDate = this.getDate(item.fEndDate);
							})
							this.fOrderTaskA.forEach(item => {
								this.fOrderTask.push(item);
							})
						} else {
							// console.log('zozheer')
							// 表示已选区域
							if(this.taskMarketA){
								this.taskMarketA.forEach(item => {
									item.fBeginDate = this.getDate(item.fBeginDate);
									item.fEndDate = this.getDate(item.fEndDate);
								})
								this.taskMarketA.forEach(item => {
									if (item.fSaleAreaIDs.indexOf(","+this.fSaleAreaIDs +",") != -1 && item
										.fSaleAreaIDs !==
										null) {
										this.taskMarket.push(item);
									}
								})
							}
							this.fGatherTaskA.forEach(item => {
								item.fBeginDate = this.getTime(item.fBeginDate);
								item.fEndDate = this.getTime(item.fEndDate);
							})
							this.fGatherTaskA.forEach(item => {
								if (item.fSaleAreaIDs.indexOf(","+this.fSaleAreaIDs+",") != -1) {
									this.fGatherTask.push(item);
								}
							})
							this.fOrderTaskA.forEach(item => {
								item.fBeginDate = this.getDate(item.fBeginDate);
								item.fEndDate = this.getDate(item.fEndDate);
							})
							this.fOrderTaskA.forEach(item => {
								if (item.fSaleAreaIDs.indexOf(","+this.fSaleAreaIDs+",") != -1) {
									this.fOrderTask.push(item);
								}
							})
						}
						let flag = false
						for(let item of this.saleArea){
							if(item.fSaleAreaID == this.fSaleAreaIDs){
								flag = true
							}
						}
						if(flag){
							this.currentNodeKey=this.fSaleAreaIDs
						}else{
							this.currentNodeKey="-1"
						}
						this.$nextTick(() => {this.$refs.tree.setCurrentKey(this.currentNodeKey)})
					}
				} else if (value == '提货任务') {
					// for(let item of this.taskMarketAreaA){
					// 	if(item.fSaleAreaID.indexOf(this.fSaleAreaIDs) == -1){
					// 		this.fSaleAreaIDs = ""
					// 		this.activeClass = undefined
					// 	}
					// }
					if (!this.fSaleAreaIDs) {
						// 表示没选区域
						if(this.taskMarketA){
							this.taskMarketA.forEach(item => {
								item.fBeginDate = this.getDate(item.fBeginDate);
								item.fEndDate = this.getDate(item.fEndDate);
							})
							this.taskMarketA.forEach(item => {
								this.taskMarket.push(item);
							})
						}
							this.arr = []
							this.taskMarketArea = this.taskMarketAreaA
							let needChange = JSON.parse(JSON.stringify(this.taskMarketArea))
							needChange.unshift({
								fSaleAreaID:"-1",
								fSaleArea:"全部",
							})
							this.treeData = this.toTreeData(needChange)
							if(this.treeData[0].fSaleAreaID == '-1'){
								this.currentNodeKey=this.treeData[0].fSaleAreaID
								this.$nextTick(() => {this.$refs.tree.setCurrentKey(this.currentNodeKey)})
							}
							this.taskMarketArea.forEach(item => {
								this.arr.push({
									fSaleAreas: item.fSaleArea,
									fSaleAreaIDs: item.fSaleAreaID
								})
							})
							this.arr.unshift({
								fSaleAreaIDs: undefined,
								fSaleAreas: "全部",
							});
							this.asideMenu = this.arr
						// console.log('提货任务没选', this.taskMarket)
					} else {
						// console.log('选了')
						// 提货任务过滤
						if(this.taskMarketA){
							this.taskMarketA.forEach(item => {
								item.fBeginDate = this.getDate(item.fBeginDate);
								item.fEndDate = this.getDate(item.fEndDate);
							})
							this.taskMarketA.forEach(item => {
								if (item.fSaleAreaIDs.indexOf(","+this.fSaleAreaIDs+",") != -1) {
									this.taskMarket.push(item);
								}
							})
						}
						if(this.taskMarketAreaA){
							this.arr = []
							this.fDeliverTaskArea = this.taskMarketAreaA
							let needChange = JSON.parse(JSON.stringify(this.fDeliverTaskArea))
							needChange.unshift({
								fSaleAreaID:"-1",
								fSaleArea:"全部",
							})
							this.treeData = this.toTreeData(needChange)
							this.fDeliverTaskArea.forEach(item => {
								this.arr.push({
									fSaleAreas: item.fSaleArea,
									fSaleAreaIDs: item.fSaleAreaID
								})
							})
							this.fSaleAreaIDsShow = ''
							this.arr.forEach(item => {
								if (item.fSaleAreaIDs == this.fSaleAreaIDs.toString()) {
									this.fSaleAreaIDsShow = item.fSaleAreaIDs;
								}
							})
							if (this.fSaleAreaIDsShow) {
								this.activeClass = this.fSaleAreaIDsShow
							} else {
								this.activeClass = undefined
							}
							this.arr.unshift({
								fSaleAreaIDs: undefined,
								fSaleAreas: "全部",
							});
							this.asideMenu = this.arr
						}
						let flag = false
						for(let item of this.fDeliverTaskArea){
							if(item.fSaleAreaID == this.fSaleAreaIDs){
								flag = true
							}
						}
						if(flag){
							this.currentNodeKey=this.fSaleAreaIDs
						}else{
							this.currentNodeKey="-1"
						}
						this.$nextTick(() => {this.$refs.tree.setCurrentKey(this.currentNodeKey)})
					}
				} else if (value == '订单任务') {
					if (!this.fSaleAreaIDs) {
						console.log(this.fOrderTaskA,'没选')
						this.fOrderTaskA.forEach(item => {
							item.fBeginDate = this.getDate(item.fBeginDate);
							item.fEndDate = this.getDate(item.fEndDate);
						})
						this.fOrderTaskA.forEach(item => {
							this.fOrderTask.push(item);
						})
						this.arr = []
						this.fOrderTaskArea = this.fOrderTaskAreaA
						let needChange = JSON.parse(JSON.stringify(this.fOrderTaskArea))
						needChange.unshift({
							fSaleAreaID:"-1",
							fSaleArea:"全部",
						})
						this.treeData = this.toTreeData(needChange)
						if(this.treeData[0].fSaleAreaID == '-1'){
							this.currentNodeKey=this.treeData[0].fSaleAreaID
							this.$nextTick(() => {this.$refs.tree.setCurrentKey(this.currentNodeKey)})
						}
						this.fOrderTaskArea.forEach(item => {
							this.arr.push({
								fSaleAreas: item.fSaleArea,
								fSaleAreaIDs: item.fSaleAreaID
							})
						})
						this.arr.unshift({
							fSaleAreaIDs: undefined,
							fSaleAreas: "全部",
						});
						this.asideMenu = this.arr
					} else {
						// console.log(fSaleAreaIDs, '订单区域选了')
						this.arr = []
						this.fOrderTaskArea = this.fOrderTaskAreaA
						let needChange = JSON.parse(JSON.stringify(this.fOrderTaskArea))
						needChange.unshift({
							fSaleAreaID:"-1",
							fSaleArea:"全部",
						})
						this.treeData = this.toTreeData(needChange)
						this.fOrderTaskArea.forEach(item => {
							// if (item.fSaleAreaID == fSaleAreaIDs.toString()) {
							this.arr.push({
								fSaleAreas: item.fSaleArea,
								fSaleAreaIDs: item.fSaleAreaID
							})
							// }
						})
						this.fSaleAreaIDsShow = ''
						this.arr.forEach(item => {
							if (item.fSaleAreaIDs == fSaleAreaIDs.toString()) {
								this.fSaleAreaIDsShow = item.fSaleAreaIDs;
							}
						})
						if (this.fSaleAreaIDsShow) {
							this.activeClass = this.fSaleAreaIDsShow
						} else {
							this.activeClass = undefined
						}
						this.arr.unshift({
							fSaleAreaIDs: undefined,
							fSaleAreas: "全部",
						});
						this.asideMenu = this.arr
						if (this.activeClass == undefined) {
							this.fOrderTaskA.forEach(item => {
								item.fBeginDate = this.getDate(item.fBeginDate);
								item.fEndDate = this.getDate(item.fEndDate);
							})
							// 订单任务过滤
							this.fOrderTaskA.forEach(item => {
								this.fOrderTask.push(item);
							})
						} else {
							this.fOrderTaskA.forEach(item => {
								item.fBeginDate = this.getDate(item.fBeginDate);
								item.fEndDate = this.getDate(item.fEndDate);
							})
							// 订单任务过滤
							this.fOrderTaskA.forEach(item => {
								if (item.fSaleAreaIDs.indexOf(","+this.fSaleAreaIDs+",") != -1) {
									this.fOrderTask.push(item);
								}
							})
						}
						let flag = false
						for(let item of this.fOrderTaskArea){
							if(item.fSaleAreaID == this.fSaleAreaIDs){
								flag = true
							}
						}
						if(flag){
							this.currentNodeKey=this.fSaleAreaIDs
						}else{
							this.currentNodeKey="-1"
						}
						this.$nextTick(() => {this.$refs.tree.setCurrentKey(this.currentNodeKey)})
					}
				} else if (value == '充值任务') {
					if (!this.fSaleAreaIDs) {
						// console.log('没选1')
						this.fGatherTaskA.forEach(item => {
							item.fBeginDate = this.getTime(item.fBeginDate);
							item.fEndDate = this.getTime(item.fEndDate);
						})
						this.fGatherTaskA.forEach(item => {
							this.fGatherTask.push(item);
						})
						this.arr = []
						this.fGatherTaskArea = this.fGatherTaskAreaA
						let needChange = JSON.parse(JSON.stringify(this.fGatherTaskArea))
						needChange.unshift({
							fSaleAreaID:"-1",
							fSaleArea:"全部",
						})
						this.treeData = this.toTreeData(needChange)
						if(this.treeData[0].fSaleAreaID == '-1'){
							this.currentNodeKey=this.treeData[0].fSaleAreaID
							this.$nextTick(() => {this.$refs.tree.setCurrentKey(this.currentNodeKey)})
						}
						this.fGatherTaskArea.forEach(item => {
							this.arr.push({
								fSaleAreas: item.fSaleArea,
								fSaleAreaIDs: item.fSaleAreaID
							})
						})
						this.arr.unshift({
							fSaleAreaIDs: undefined,
							fSaleAreas: "全部",
						});
						this.asideMenu = this.arr
					} else {
						// 充值任务过滤
						// console.log('选了2')
						this.arr = []
						this.fGatherTaskArea = this.fGatherTaskAreaA
						let needChange = JSON.parse(JSON.stringify(this.fGatherTaskArea))
						needChange.unshift({
							fSaleAreaID:"-1",
							fSaleArea:"全部",
						})
						this.treeData = this.toTreeData(needChange)
						this.fGatherTaskArea.forEach(item => {
							this.arr.push({
								fSaleAreas: item.fSaleArea,
								fSaleAreaIDs: item.fSaleAreaID
							})
						})
						this.fSaleAreaIDsShow = ''
						this.arr.forEach(item => {
							if (item.fSaleAreaIDs == fSaleAreaIDs.toString()) {
								this.fSaleAreaIDsShow = item.fSaleAreaIDs;
							}
						})
						if (this.fSaleAreaIDsShow) {
							this.activeClass = this.fSaleAreaIDsShow
						} else {
							this.activeClass = undefined
						}
						this.arr.unshift({
							fSaleAreaIDs: undefined,
							fSaleAreas: "全部",
						});
						this.asideMenu = this.arr
						if (this.activeClass == undefined) {
							this.fGatherTaskA.forEach(item => {
								item.fBeginDate = this.getTime(item.fBeginDate);
								item.fEndDate = this.getTime(item.fEndDate);
							})
							this.fGatherTaskA.forEach(item => {
								// if (item.fSaleAreaIDs == fSaleAreaIDs.toString()) {
								this.fGatherTask.push(item);
								// }
							})
						} else {
							this.fGatherTaskA.forEach(item => {
								item.fBeginDate = this.getTime(item.fBeginDate);
								item.fEndDate = this.getTime(item.fEndDate);
							})
							this.fGatherTaskA.forEach(item => {
								if (item.fSaleAreaIDs.indexOf(","+this.fSaleAreaIDs+",") != -1) {
									this.fGatherTask.push(item);
								}
							})
						}
						let flag = false
						for(let item of this.fGatherTaskArea){
							if(item.fSaleAreaID == this.fSaleAreaIDs){
								flag = true
							}
						}
						if(flag){
							this.currentNodeKey=this.fSaleAreaIDs
						}else{
							this.currentNodeKey="-1"
						}
						this.$nextTick(() => {this.$refs.tree.setCurrentKey(this.currentNodeKey)})
					}

				}
			},
			//领取任务
			pickUpTheTask(fDeliverTaskID, data) {
				this.ApiRequestPostNOMess('/api/mall/ebactivity/deliver-task-record/create', {
					fDeliverTaskID: fDeliverTaskID
				}).then(
					result => {
						console.log(result);
						this.$store.commit("changeSearchData", data.fGoodsNames);	
						this.$router.push("/Goods");
						// this.getListByNoGet();
						// this.getListAllNoGet();//查看所有任务
						// this.getListByCurrentTask();
						// this.getAllCurrent();//获取当前我的任务
						// this.getTasksStatistic()
					},
					rej => {
						console.log(123)
						this.getListAllNoGet()
						this.$forceUpdate()
					}
				);
			},

			//获取领取的任务列表
			getListByCurrentTask() {
				let parms = {
					maxResultCount: 100,
					skipCount: 0
				};
				if (this.shoptype == '2') {
					parms.fShopUnitID = this.shopId;
				}
				this.ApiRequestPostNOMess('/api/mall/ebactivity/deliver-task/get-list-by-current-task', parms).then(
					result => {
						//console.log(result);
						if (result.obj.items.length > 0) {
							result.obj.items.forEach((item) => {
								item.fBeginDate = this.getDate(item.fBeginDate);
								item.fEndDate = this.getDate(item.fEndDate);
								if (item.fGoodsNames != '') {
									item.fGoodsNamesList = item.fGoodsNames.split(',')
								}
								if (item.fDepotNames != '') {
									item.fDepotNamesList = item.fDepotNames.split(',')
								}
							});
						}
						this.taskMy = result.obj.items;
					},
					rej => {}
				);
			},
			//显示我的任务页面
			getAllCurrent( value) {
				let parms = {
					maxResultCount: 100,
					skipCount: 0,
					fBeginDate: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
					fEndDate: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
				};
				if (this.shoptype == '2') {
					parms.fShopUnitID = this.shopId;
				}
				this.ApiRequestPostNOMess('/api/mall/ebactivity/deliver-task/get-list-by-current-task-new', parms).then(
					result => {
						this.saleArea = []
						this.taskMy = []
						this.fOrderTaskMy = []
						this.fGatherTaskMy = []
						if (value == '全部任务' || value == undefined) {
							result.obj.fDeliverTask.items.forEach(item => {
								item.fBeginDate = this.getDate(item.fBeginDate);
								item.fEndDate = this.getDate(item.fEndDate);
							})
							result.obj.fDeliverTask.items.forEach(item => {
								this.taskMy.push(item);
								//=====
								item.fGradeIDsList = item.fGradeIDs.split(',');
								if (item.fGoodsNames != '') {
									item.fGoodsNamesList = item.fGoodsNames.split(',');
								}
								if (item.fDepotNames != '') {
									item.fDepotNamesList = item.fDepotNames.split(',');
								}
								//========
							})
							result.obj.fGatherTask.items.forEach(item => {
								item.fBeginDate = this.getTime(item.fBeginDate);
								item.fEndDate = this.getTime(item.fEndDate);
							})
							result.obj.fGatherTask.items.forEach(item => {
								this.fGatherTaskMy.push(item);
							})
							result.obj.fOrderTask.items.forEach(item => {
								item.fBeginDate = this.getDate(item.fBeginDate);
								item.fEndDate = this.getDate(item.fEndDate);
								//==========
								item.fGradeIDsList = item.fGradeIDs.split(',');
								if (item.fGoodsFullName != '') {
									item.fGoodsNamesList = item.fGoodsFullName.split(',');
								}
								//===========
							})
							result.obj.fOrderTask.items.forEach(item => {
								this.fOrderTaskMy.push(item);
							})
						} else if (value == '提货任务') {
							if(result.obj.fDeliverTask.items){
								result.obj.fDeliverTask.items.forEach(item => {
									item.fBeginDate = this.getDate(item.fBeginDate);
									item.fEndDate = this.getDate(item.fEndDate);
									//=====
									item.fGradeIDsList = item.fGradeIDs.split(',');
									if (item.fGoodsNames != '') {
										item.fGoodsNamesList = item.fGoodsNames.split(',');
									}
									if (item.fDepotNames != '') {
										item.fDepotNamesList = item.fDepotNames.split(',');
									}
									//========
								})
								result.obj.fDeliverTask.items.forEach(item => {
									this.taskMy.push(item);
								})
							}
						} else if (value == '订单任务') {
							if(result.obj.fOrderTask.items){
								result.obj.fOrderTask.items.forEach(item => {
									item.fBeginDate = this.getDate(item.fBeginDate);
									item.fEndDate = this.getDate(item.fEndDate);
									//==========
									item.fGradeIDsList = item.fGradeIDs.split(',');
									if (item.fGoodsFullName != '') {
										item.fGoodsNamesList = item.fGoodsFullName.split(',');
									}
									//===========
								})
								result.obj.fOrderTask.items.forEach(item => {
									this.fOrderTaskMy.push(item);
								})
							}
						} else if (value == '充值任务') {
							if(result.obj.fGatherTask.items){
								result.obj.fGatherTask.items.forEach(item => {
									item.fBeginDate = this.getTime(item.fBeginDate);
									item.fEndDate = this.getTime(item.fEndDate);
								})
								result.obj.fGatherTask.items.forEach(item => {
									this.fGatherTaskMy.push(item);
								})
							}
						}
					},
					rej => {}
				);
			},
			//领取奖励
			receiveReward(e) {
				console.log(e, '领取奖励');
				this.ApiRequestPost('/api/mall/ebactivity/deliver-task-record/ReceivePoint', {
					id: e.fBillID
				}).then(
					result => {
						console.log(result);
						this.getTasksStatistic();
						this.getMyPoint();
						// this.getListByNoGet();
						this.getListAllNoGet(); //查看所有任务
						// this.getListByCurrentTask();
						this.getAllCurrent(); //获取当前我的任务
					},
					rej => {}
				);
			},
			//删除任务(仓库)
			deleteTask(e) {
				console.log(e);
				let parmas = {
					fBillID: e.records.fBillID,
					frv: e.records.frv,
					rowStatus: this.rowStatus_delete
				}
				this.$confirm('亲, 是否确认删除任务?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					console.log(e, '删除任务');
					this.ApiRequestPost('/api/mall/ebactivity/deliver-task-record/delete', parmas).then(
						result => {
							console.log(result);
							this.getTasksStatistic();
							this.getMyPoint();
							// this.getListByNoGet();
							this.getListAllNoGet(); //查看所有任务
							// this.getListByCurrentTask();
							this.getAllCurrent(); //获取当前我的任务
							this.$forceUpdate()
						},
						rej => {}
					);
				}).catch(() => {})
			},
			//删除任务（订单）
			deleteTaskorder(e) {
				console.log(e);
				let parmas = {
					fBillID: e.fRecord.fBillID,
					frv: e.fRecord.frv,
					rowStatus: this.rowStatus_delete
				}
				this.$confirm('亲, 是否确认删除任务?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					console.log(e, '删除任务');
					this.ApiRequestPost('/api/mall/ebactivity/order-task-record/delete', parmas).then(
						result => {
							console.log(result);
							this.getTasksStatistic();
							this.getMyPoint();
							// this.getListByNoGet();
							this.getListAllNoGet(); //查看所有任务
							// this.getListByCurrentTask();
							this.getAllCurrent(); //获取当前我的任务
							this.$forceUpdate()
						},
						rej => {}
					);
				}).catch(() => {})
			},
			//删除任务（充值）
			deleteTaskgather(e) {
				console.log(e);
				let parmas = {
					fBillID: e.fRecord.fBillID,
					frv: e.fRecord.frv,
					rowStatus: this.rowStatus_delete
				}
				this.$confirm('亲, 是否确认删除任务?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					console.log(e, '删除任务');
					this.ApiRequestPost('/api/mall/ebactivity/gather-task-record/delete', parmas).then(
						result => {
							console.log(result);
							this.getTasksStatistic();
							this.getMyPoint();
							// this.getListByNoGet();
							this.getListAllNoGet(); //查看所有任务
							// this.getListByCurrentTask();
							this.getAllCurrent(); //获取当前我的任务
							this.$forceUpdate()
						},
						rej => {}
					);
				}).catch(() => {})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.delivery-method{
		.delivery-img{
			width: 12px;
			height: 12px;
			margin: 0 8px 0 1px;
		}
	}	
	/deep/ .el-tabs__nav-wrap::after {
		background-color: #ffffff;
		// background-color: red;
		// width: 800px;
	}
	
	/deep/ .el-tabs__header{
		width: 400px;
	}
	
	/deep/ .el-tabs__nav-scroll{
		padding-left: 15px;
	}
	
	/deep/ .el-tabs__item.is-active {
		color: #0173fe;
	}

	/deep/ .el-tabs__active-bar {
		width: 75px !important;
		height: 2px;
		margin-left: -10px;
		background-color: #0173fe;
	}

	/deep/ .el-menu-vertical-demo {
		// height: 45px;
		// width: 100%;
		// line-height: 45px;
		// box-sizing: border-box;
		// padding-left: 10px;
		border-left: 1px solid #f2f2f2;
		border-right: 1px solid #f2f2f2;
		border-bottom: 1px solid  #f2f2f2;
		width: 316px;
		height: 49px;
		line-height: 49px;
		font-size: 15px;
		box-sizing: border-box;
		padding-left: 10px;
		font-family:400;
		font-weight: normal;
	}

	.el-menu-vertical-demo:first-child {
		border-top: 1px solid #f2f2f2;
	}

	/deep/ .active {
		background-color: #0173FE;
		color: #ffffff;
		height: 49px;
	}

	/deep/ .el-menu-item {
		height: 40px;
		line-height: 40px;
		border-left: 1px solid #969696;
		border-right: 1px solid #969696;
		border-bottom: 1px solid #969696;
		border-top: 1px solid #969696;
	}

	/deep/ .el-menu-item:first-child {
		border-top: 1px solid #969696;
	}


	/deep/ .el-menu-item:nth-last-child(1) {
		border-bottom: 1px solid #969696;
	}

	/deep/.el-menu-item:last-child {
		border-bottom: 1px solid #969696;
	}

	.el-date-editor--daterange.el-input__inner {
		margin-top: 30px;
		width: 100%;
	}

	/deep/ .el-date-editor .el-range-separator {
		line-height: 21px;
	}

	/deep/ .el-date-editor .el-range__icon {
		line-height: 21px;
	}

	/deep/ .el-date-editor .el-range__close-icon {
		line-height: 21px;
	}





	// /deep/ .el-submenu__title {
	// 	height: 40px;
	// 	line-height: 40px;
	// 	border-top: 1px solid #969696;
	// 	border-left: 1px solid #969696;
	// 	border-right: 1px solid #969696;
	// }

	// /deep/ .el-menu-item-group__title {
	// 	padding: 0px;
	// 	line-height: 0;
	// }

	// .last-index {
	// 	border-bottom: 1px solid #333333;
	// }

	.theme-text-color {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.theme-border-color {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.theme-bg-color {
		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	.maink {
		margin: 0 auto;
	}

	.self-color-ffff00 {
		color: #ffff00;
	}

	.task-left-box {
		width: 316px;
		height: 393px;

		.area {
			height: 45px;
			width: 100%;
			border: 1px solid #9C9C9C;
			line-height: 45px;
			box-sizing: border-box;
			padding-left: 10px;
		}
	}

	.task-left-title {
		width: 316px;
		height: 264px;
		position: relative;
	}

	.task-left-title-bg {
		width: 316px;
		height: 316px;
		position: absolute;
		left: 0;
		top: -35px;
		opacity: 0.5;
		z-index: 0;
	}

	.task-left-title-text {
		font-size: 68px;
		color: #FFFFCC;
		z-index: 1;
	}

	.task-right-box {
		width: 784px;
	}


	.task-box {
		width: 750px;
		height: 270px;
		background-image: linear-gradient(to bottom, #ff9900, #F8F7FE);
	}

	.task-box-brief {
		width: 750px;
		height: 140px;
	}

	.task-box-brief-item {
		margin: 0 50px;
	}

	.task-card-box {}

	.task-card {
		width: 746px;
		position: relative;
	}

	.task-card-title {
		width: 700px;
		height: 70px;
		background-color: #FFF7EE;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
	}

	.task-card-bg {
		position: absolute;
		top: 0;
		left: 0;
		height: 70px;
		padding-left: 30px;
		color: #f2f2f2;
		line-height: 100px;
		z-index: 1;
	}

	.task-card-tabs {
		width: 716px;
		height: 70px;
		padding-left: 30px;
		position: relative;
		z-index: 10;
	}

	.task-card-tab-margin-r {
		margin-right: 40px;
	}

	.task-card-item {
		width: 686px;
		padding: 0px 30px 20px;
	}

	.icon-box {
		width: 42px;
		height: 42px;
	}

	.icon-bg-blue {
		background-image: linear-gradient(to top, #20BEFB, #0B7AF4);
	}

	.icon-bg-orange {
		background-image: linear-gradient(to top, #FEE272, #FA9101);
	}

	.icon-bg-red {
		background-image: linear-gradient(to top, #FE9576, #F95453);
	}

	.task-card-item-content {
		width: 630px;
	}

	.shoucang-one {
		margin-left: -6px;
	}

	.task-card-item-tag {
		// width: 104px;
		line-height: 18px;
		padding: 0 2px;
	}

	.task-card-item-btn {
		min-width: 110px;
		line-height: 30px;
		text-align: center;
	}

	.task-card-item-btn-h-30 {
		height: 30px;
	}

	.task-card-item-btn-h-28 {
		height: 28px;
	}

	.task-card-item-btn-blue-white {
		@include themify($themes) {
			background-color: themed('themes_color');
		}

		color: #fff;
	}

	.task-card-item-btn-orange-white {
		background-color: #ff9900;
		color: #fff;
	}

	.task-card-item-btn-white-blue {
		background-color: #fff;

		@include themify($themes) {
			border: 1px solid themed('themes_color');
			color: themed('themes_color');
		}
	}

	.task-card-item-btn-orange-white {
		background-color: #ccc;
		color: #fff;
	}

	.task-card-item-progress-text {
		width: 110px;
		text-align: center;
	}

	.iconrenwu {
		background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNjA2MzY2Mzc5MTQzIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjEyNjk4IiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiPjxkZWZzPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PC9zdHlsZT48L2RlZnM+PHBhdGggZD0iTTUxMiA1MTJtLTQ0Ny44IDBhNDQ3LjggNDQ3LjggMCAxIDAgODk1LjYgMCA0NDcuOCA0NDcuOCAwIDEgMC04OTUuNiAwWiIgZmlsbD0iI0Y0QTYyNSIgb3BhY2l0eT0iLjIiIHAtaWQ9IjEyNjk5Ij48L3BhdGg+PHBhdGggZD0iTTY3Mi43IDI3OS4xYzIwLjEgMCAzNi40IDE3LjggMzYuNCAzOS45djQxNS4xYzAgOS00LjIgMTcuNC0xMS4xIDIyLjMtNi45IDQuOS0xNS42IDUuNi0yMy4xIDEuOWwtMTUyLjItNzQuOGMtNi4zLTMuMS0xMy41LTMuMS0xOS44IDBsLTE1My44IDc0LjljLTcuNSAzLjctMTYuMiAyLjktMjMuMS0yLTYuOS00LjktMTEtMTMuMy0xMS0yMi4zVjMxOWMwLTIyIDE2LjMtMzkuOSAzNi40LTM5LjloMzIxLjN6TTUzOS4zIDQ4MS43SDQxMmMtNi43IDAtMTIuMSA1LjktMTIuMSAxMy4zIDAgNy4zIDUuNCAxMy4zIDEyLjEgMTMuM2gxMjcuM2M2LjcgMCAxMi4xLTUuOSAxMi4xLTEzLjMgMC03LjMtNS40LTEzLjMtMTIuMS0xMy4zeiBtNjYuNy05M0g0MTJjLTYuNyAwLTEyLjEgNS45LTEyLjEgMTMuM3M1LjQgMTMuMyAxMi4xIDEzLjNoMTk0YzYuNyAwIDEyLjEtNS45IDEyLjEtMTMuM3MtNS40LTEzLjMtMTIuMS0xMy4zeiIgZmlsbD0iI0Y0QTYyNSIgcC1pZD0iMTI3MDAiPjwvcGF0aD48L3N2Zz4=');
		background-size: cover;
		width: 15px;
		height: 15px;
	}

	.task-card-item-progress {
		width: 630px;
		height: 50px;
		// border: 1px solid black;
	}

	.normal-line {
		width: 630px;
		height: 10px;
		position: relative;
		top: 25px;
		z-index: 1;
	}

	.active-line {
		width: 0;
		height: 10px;
		position: relative;
		top: 25px;
		margin-top: -10px;
		z-index: 2;
	}

	.active-text {
		width: 160px;
		position: absolute;
		right: -80px;
		top: -45px;
	}

	.normal-line-dot {
		width: 20px;
		height: 20px;
		font-size: 20px;
		margin-top: -16px;
		position: relative;
		left: -10px;
		z-index: 3;
	}

	.icon-wujiaoxingxingxingshoucangmoren {
		font-size: 12px;
	}

	.normal-text-top {
		width: 160px;
		position: absolute;
		right: -70px;
		top: -18px;
	}

	.normal-text-bottom {
		width: 160px;
		position: absolute;
		right: -70px;
		bottom: -18px;
	}

	.icon-bg-FF9900 {
		background-color: #ff9900 !important;
	}

	.text-333 {
		color: #333333 !important;
	}

	.text-ff6600 {
		color: #ff6600 !important;
	}

	.svg-no-content {
		width: 500px;
		height: 500px;
		margin-top: 150px;
	}

	.delete-hover:hover {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}
	/deep/ .el-tree-node__content{
		height: 49px;
		line-height: 49px;
	}
	/deep/ .el-tree-node:focus>.el-tree-node__content {
	  background-color:#0173FE !important;
	  color:white!important
}
/deep/.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
	 background-color:#0173FE !important;
	 color:white
}
</style>
